import { ColorThemeService } from '../../../../navigation/services/color-theme-service';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { PackagingUnitTypeService } from '../../../../navigation/services/packaging-unit-type-service';
import { AuthService } from '../../../../services/auth-service';
import { CreateUpdateHandler } from '../../../../services/packaging-services/create-update-handler';
import { ComponentCanDeactivate } from '../../../../services/unsaved-changes-guard';
import { Component, OnInit, HostListener, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PackagingUnitNavigationService } from 'src/app/navigation/services/navigation-services/packaging-unit-navigation.service';
import { ChangedPackagingPartDisplayInfo, CreateUpdatePackagingPartComponent } from '../../../shared-components/parent-components/create-update-packaging-part/create-update-packaging-part.component';
import { FileApiService } from 'src/app/data-transfer/services/file-api-service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { VersionDto } from 'src/app/data-transfer/entities/version-dto';
import { PackagingPartTypeDto } from 'src/app/data-transfer/entities/packaging-part-type-dto';
import { PackagingUnitApiService } from 'src/app/data-transfer/services/packaging-unit-api-service';
import { COMPONENT_CATEGORIES, ComponentTypeService } from 'src/app/navigation/services/component-type-service';
import { PackagingComponentTypesEnum } from 'src/app/model/packaging-component-types-enum';
import { PackagingUnitHtmlTemplateComponent } from '../common/packaging-unit-html-template/packaging-unit-html-template.component';
import { PackagingComponentEntryDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-entry-dto';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { BooleanDictionary, DictionaryHandler, TriStateDictionary } from 'src/app/model/dictionary';
import { MainBodyEntryDto } from 'src/app/data-transfer/entities/component-entities/main-body-entry-dto';
import { ClosureEntryDto } from 'src/app/data-transfer/entities/component-entities/closure-entry-dto';
import { InlayEntryDto } from 'src/app/data-transfer/entities/component-entities/inlay-entry-dto';
import { PackagingAidEntryDto } from 'src/app/data-transfer/entities/component-entities/packaging-aid-entry-dto';
import { LabelEntryDto } from 'src/app/data-transfer/entities/component-entities/label-entry-dto';
import { SleeveEntryDto } from 'src/app/data-transfer/entities/component-entities/sleeve-entry-dto';
import { InMoldLabelEntryDto } from 'src/app/data-transfer/entities/component-entities/in-mold-label-entry-dto';
import { AdditionalPackagingComponent } from 'src/app/components/shared-components/additional-packaging/additional-packaging.component';
import { DecorationEntryDto } from 'src/app/data-transfer/entities/component-entities/decoration-entry-dto';

export class ChangedComponentDisplayInfo extends ChangedPackagingPartDisplayInfo {
  subtype = '';
}

@Component({
  selector: 'app-create-update-packaging-unit',
  templateUrl: './create-update-packaging-unit.component.html',
  styleUrls: ['./create-update-packaging-unit.component.scss']
})
export class CreateUpdatePackagingUnitComponent extends CreateUpdatePackagingPartComponent implements OnInit, ComponentCanDeactivate {

  @ViewChild('mainBody') mainBodyDirective!: AdditionalPackagingComponent;
  @ViewChild('closure') closureDirective!: AdditionalPackagingComponent;
  @ViewChild('decoration') decorationDirective!: AdditionalPackagingComponent;
  @ViewChild('inlay') inlayDirective!: AdditionalPackagingComponent;
  @ViewChild('packAid') packAidDirective!: AdditionalPackagingComponent;

  componentsCount!: { [key: number]: number };

  packagingUnitForm!: FormGroup;
  packagingUnit = new PackagingUnitDto();
  packagingUnitTypeImageSource = '';
  mainBodyTypeImageSource = '';

  packagingUnitType?: PackagingPartTypeDto;
  componentTypes = PackagingComponentTypesEnum;
  changedPackagingPartsInfo: ChangedComponentDisplayInfo[] = [];

  fillingVolume: number | undefined;

  isTransportPackagingDictionary: TriStateDictionary[];
  isPackagingPartOfProductDictionary: TriStateDictionary[];
  isMultiUseDictionary: TriStateDictionary[];

  get primaryPackagingControl(): FormControl {
    return this.packagingUnitForm.controls.primaryPackagingControl as FormControl;
  }

  constructor(
    protected createUpdateHandler: CreateUpdateHandler,
    protected dialog: MatDialog,
    protected packagingUnitApiService: PackagingUnitApiService,
    protected authService: AuthService,
    protected colorThemeService: ColorThemeService,
    protected packagingUnitTypeService: PackagingUnitTypeService,
    protected componentTypeService: ComponentTypeService,
    protected cdr: ChangeDetectorRef,
    protected route: ActivatedRoute,
    protected spinner: NgxSpinnerService,
    protected fileApiService: FileApiService,
    private formBuilder: FormBuilder,
    private navigationService: PackagingUnitNavigationService,
    private dictionaryHandler: DictionaryHandler
  ) {
    super(createUpdateHandler, dialog, colorThemeService, authService, cdr, route, spinner, fileApiService);

    this.isTransportPackagingDictionary = this.dictionaryHandler.getTransportDictionary();
    this.isMultiUseDictionary = this.dictionaryHandler.getIsMultiUseDictionary();
    this.isPackagingPartOfProductDictionary = this.dictionaryHandler.getPackagingPartOfDictionary();
  }

  private setComponentsCount() {
    this.componentsCount = {
      [PackagingComponentTypesEnum.Closure]: this.packagingUnit.closures.length,
      [PackagingComponentTypesEnum.Decoration]: this.packagingUnit.labels.length +
        this.packagingUnit.sleeves.length + this.packagingUnit.inMoldLabels.length,
      [PackagingComponentTypesEnum.Inlay]: this.packagingUnit.inlays.length,
      [PackagingComponentTypesEnum.PackagingAid]: this.packagingUnit.packagingAids.length
    }
  }

  ngOnInit() {
    this.routeParamsSubscription = this.route.params.subscribe(params => {
      if (params.candidateId) { this.isPreview = true; }
    });
    this.routeDataSubscription = this.route.data.subscribe(data => {
      if (data.packagingUnit) {
        this.packagingUnit = data.packagingUnit;
        this.currentDirectoryId = data.packagingUnit.directoryId;
        if (data.packagingUnit.mainBody) {
          this.setPackagingUnitTypeFromId(data.packagingUnit.mainBody.underlyingComponent.packagingComponentSubtypeId);
        }
        if (this.packagingUnitForm) {
          window.location.reload();
          this.initPackaging();
        }
        this.selectedTags = this.allTags.filter(tag => this.packagingUnit.associatedTagIdentifiers.includes(tag.id ?? -1) ?? false);
      } else {
        this.packagingUnit.mainBody = undefined;
      }
      if (data.changedComponentsIds && data.changedComponentsIds.length > 0) {
        this.setChangedComponentsInfo(this.packagingUnit, data.changedComponentsIds);
      }
      if (data.permissions) {
        this.permissions = data.permissions;
        this.isEditPermitted = this.permissions?.write ?? true;
      }
    });
    this.setComponentsCount();
    this.packagingUnitForm = this.getFormGroup();
    this.setPackagingType(this.packagingUnitForm.controls.mainBodyControl.value[0]?.underlyingComponent.packagingComponentSubtypeId);
    this.initPackaging();
  }

  setPackagingType(packagingTypeId: number) {
    if (packagingTypeId != null) {
      this.setPackagingUnitTypeFromId(packagingTypeId);
    } else {
      this.packagingUnitType = undefined;
    }
    this.packagingUnitTypeImageSource = this.packagingUnitTypeService.getPackagingUnitTypeImage(packagingTypeId);
  }

  setTags() {
    this.packagingUnit.associatedTagIdentifiers = this.selectedTags.map(tag => tag.id ?? -1);
    this.cdr.detectChanges();
  }

  private getFormGroup(): FormGroup {
    return this.formBuilder.group({
      newVersionName: [''],
      primaryPackagingControl: [this.packagingUnit],
      mainBodyControl: this.packagingUnit.mainBody ? this.formBuilder.control([this.packagingUnit.mainBody]) : this.formBuilder.control([]),
      closuresControl: this.formBuilder.control(this.packagingUnit.closures),
      decorationsControl:
        this.formBuilder.control(this.packagingUnit.sleeves.concat(this.packagingUnit.labels).concat(this.packagingUnit.inMoldLabels)),
      inlaysControl: this.formBuilder.control(this.packagingUnit.inlays),
      packagingAidsControl: this.formBuilder.control(this.packagingUnit.packagingAids),
      isTransportPackaging: [this.packagingUnit.isTransportPackaging ?? null],
      isPackagingPartOfProduct: [this.packagingUnit.isPackagingPartOfProduct ?? null],
      isMultiUsePackaging: [this.packagingUnit.isMultiUsePackaging ?? null]
    });
  }

  updateMainBodyVolume(volume: number) {
    this.fillingVolume = volume;
  }

  updateComponentCount(count: { key: number, count: number }) {
    this.componentsCount[count.key] = count.count;
  }

  private initPackaging() {
    this.isHistory = this.packagingUnit.id != null && !this.packagingUnit.isCurrentVersion;
    this.isTracked = this.packagingUnit.hasExternalTracking ?? false;
    const lockFunction = (packagingUnitId: number) => this.packagingUnitApiService.lockPackagingUnit(packagingUnitId, true);
    this.setUpLockAndHeartbeat(this.packagingUnitForm, lockFunction, this.packagingUnit?.id);
    super.disableFormIfRequired(this.packagingUnitForm);
    this.historyVersions = [{
      id: this.packagingUnit.id,
      versionNumber: this.packagingUnit.version,
      versionName: this.packagingUnit.versionName,
      creationTimestamp: this.packagingUnit.creationTimestamp,
      isCurrentVersion: this.packagingUnit.isCurrentVersion
    }];
  }

  loadHistoryVersions() {
    const versionsFunction = (packagingUnitId: number) => this.packagingUnitApiService.getPackagingUnitHistoryVersions(packagingUnitId);
    super.loadHistoryVersions(this.packagingUnit.id, versionsFunction);
  }

  onHistoryVersionChange(version: number) {
    const navigationFunction = (packagingUnitId: number, packagingUnitVersion?: number) =>
      this.navigationService.navigateToPackagingUnit(packagingUnitId, packagingUnitVersion);
    super.onHistoryVersionChange(version, navigationFunction);
  }

  editVersionName(packagingUnit: VersionDto) {
    const renameFunction = (id: number, version: number, name: string) =>
      this.packagingUnitApiService.renamePackagingUnit(id, version, name, true);
    super.editVersionName(packagingUnit, this.packagingUnit, renameFunction);
  }

  private setPackagingUnitTypeFromId(packagingUnitTypeId: number) {
    this.packagingUnitType = this.packagingUnitTypeService.getPackagingUnitTypeDtoFromId(packagingUnitTypeId);
  }

  async releaseComponent() {
    const releaseFunction = (packagingUnitId: number) => this.packagingUnitApiService.releasePackagingUnit(packagingUnitId, true);
    super.releaseComponent(this.packagingUnit.id, releaseFunction);
  }

  async acceptComponentChange(componentId: number) {
    if (this.packagingUnit.id == null) { return; }
    const shouldCreateNewVersion = await super.shouldCreateNewVersion(true, PackagingPart.Unit);
    if (shouldCreateNewVersion == null) { return; }
    const acceptFunction = (packagingUnitId: number, componentToAcceptId: number, shouldCreateNewVersion: boolean) =>
      this.packagingUnitApiService.acceptComponentChange(packagingUnitId, componentToAcceptId, shouldCreateNewVersion);
    super.acceptChange(this.packagingUnit.id, componentId, acceptFunction, shouldCreateNewVersion);
  }

  declineComponentChange(componentId: number) {
    if (this.packagingUnit.id == null) { return; }
    const declineFunction = (packagingUnitId: number, componentToAcceptId: number) =>
      this.packagingUnitApiService.declineComponentChange(packagingUnitId, componentToAcceptId);
    super.declineChange(this.packagingUnit.id, componentId, declineFunction);
  }

  /**
   * If no changes were made, user can navigate away from page
   */
  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return (this.packagingUnitForm.pristine && !this.filesEdited)
      || this.isPreview || this.isUserValidator || this.isUserAdmin || this.isHistory;
  }

  private setChangedComponentsInfo(packagingUnit: PackagingUnitDto, changedComponentIds: number[]) {
    for (const changedId of changedComponentIds) {
      const changedMainBody = packagingUnit.mainBody?.underlyingComponent.id === changedId ? packagingUnit.mainBody : null;
      const changedClosure = packagingUnit.closures.find(x => x.underlyingComponent.id === changedId);
      const changedLabel = packagingUnit.labels.find(x => x.underlyingComponent.id === changedId);
      const changedSleeve = packagingUnit.sleeves.find(x => x.underlyingComponent.id === changedId);
      const changedInMold = packagingUnit.inMoldLabels.find(x => x.underlyingComponent.id === changedId);
      const changedPackAid = packagingUnit.packagingAids.find(x => x.underlyingComponent.id === changedId);
      const changedInlay = packagingUnit.inlays.find(x => x.underlyingComponent.id === changedId);

      let categoryKey: number;
      let changedComponent: PackagingComponentEntryDto | null = null;

      if (changedMainBody) {
        changedComponent = changedMainBody;
        categoryKey = PackagingComponentTypesEnum.MainBody;
      } else if (changedClosure) {
        changedComponent = changedClosure;
        categoryKey = PackagingComponentTypesEnum.Closure;
      } else if (changedLabel || changedSleeve || changedInMold) {
        changedComponent = changedLabel ? changedLabel : changedSleeve ? changedSleeve : changedInMold ? changedInMold : null;
        categoryKey = PackagingComponentTypesEnum.Decoration;
      } else if (changedInlay) {
        changedComponent = changedInlay;
        categoryKey = PackagingComponentTypesEnum.Inlay;
      } else if (changedPackAid) {
        changedComponent = changedPackAid;
        categoryKey = PackagingComponentTypesEnum.PackagingAid;
      }
      if (!changedComponent) { return; }
      const categoryName = COMPONENT_CATEGORIES.find(x => x.key === categoryKey)?.label ?? '';
      this.changedPackagingPartsInfo.push({
        id: changedComponent.underlyingComponent.id ?? -1,
        name: changedComponent.underlyingComponent.articleName,
        packagingPart: PackagingPart.Component,
        type: categoryName,
        subtype: changedComponent.underlyingComponent.packagingComponentSubtypeName
      });
    }
  }

  async onSubmit() {
    this.submitted = true;
    this.packagingUnitForm.updateValueAndValidity();
    this.packagingUnitForm.markAllAsTouched();
    if (this.packagingUnitForm.invalid) {
      super.openFormInvalidDialog();
    } else {
      const dirId = this.currentDirectoryId != null ? this.currentDirectoryId : await super.selectDirectory();
      if (dirId == null) { return; }
      this.addNewPackagingUnit(dirId);
    }
  }

  isLabel = (subtypeId: number) => { return this.componentTypeService.isDecorationOfTypeLabel(subtypeId); }
  isSleeve = (subtypeId: number) => { return this.componentTypeService.isDecorationOfTypeSleeve(subtypeId); }
  isInMold = (subtypeId: number) => { return this.componentTypeService.isDecorationOfTypeInMold(subtypeId); }

  private async addNewPackagingUnit(directoryId: number) {

    this.packagingUnit.directoryId = directoryId;

    PackagingUnitHtmlTemplateComponent.updatePackagingUnit(
      (this.packagingUnitForm.controls.primaryPackagingControl as FormGroup).getRawValue(), this.packagingUnit);
    this.packagingUnit.versionName = this.packagingUnitForm.value?.newVersionName ?? null;

    this.packagingUnit.mainBody = this.mainBodyDirective.getComponentEntries()[0] as MainBodyEntryDto;
    this.packagingUnit.closures = this.closureDirective.getComponentEntries() as ClosureEntryDto[];
    const decorations = this.decorationDirective.getComponentEntries() as DecorationEntryDto[];
    const labels = decorations.filter(x => this.isLabel(x.underlyingComponent.packagingComponentSubtypeId)) as LabelEntryDto[];
    const sleeves = decorations.filter(x => this.isSleeve(x.underlyingComponent.packagingComponentSubtypeId)) as SleeveEntryDto[];
    const inMolds = decorations.filter(x => this.isInMold(x.underlyingComponent.packagingComponentSubtypeId)) as InMoldLabelEntryDto[];
    this.packagingUnit.labels = labels;
    this.packagingUnit.sleeves = sleeves;
    this.packagingUnit.inMoldLabels = inMolds;
    this.packagingUnit.inlays = this.inlayDirective.getComponentEntries() as InlayEntryDto[];
    this.packagingUnit.packagingAids = this.packAidDirective.getComponentEntries() as PackagingAidEntryDto[];


    this.packagingUnit.isTransportPackaging = this.packagingUnitForm.value?.isTransportPackaging ?? null;
    this.packagingUnit.isPackagingPartOfProduct = this.packagingUnitForm.value?.isPackagingPartOfProduct ?? null;
    this.packagingUnit.isMultiUsePackaging = this.packagingUnitForm.value?.isMultiUsePackaging ?? null;
    await super.updateImagesAndDocuments(this.packagingUnit);

    const packagingUnitChanged = !this.packagingUnitForm.pristine;
    const componentsChanged = this.mainBodyDirective.isPristine() || this.closureDirective.isPristine() ||
      this.decorationDirective.isPristine() || this.inlayDirective.isPristine() || this.packAidDirective.isPristine();

    let shouldCreateNewVersion = true;
    if (this.packagingUnit.id != null && (packagingUnitChanged || componentsChanged)) {
      shouldCreateNewVersion = await super.shouldCreateNewVersion(true, PackagingPart.Unit);
      if (shouldCreateNewVersion == null) { return; }
    }

    const saveFunction = (packagingItem: PackagingUnitDto, shouldCreateNewVersion: boolean) =>
      this.packagingUnitApiService.putPackagingUnit(packagingItem, shouldCreateNewVersion);
    const navFunction = (id: number, version: number) => this.navigationService.navigateToPackagingUnit(id, version);
    super.putPackagingItem(this.packagingUnit, this.packagingUnitForm, saveFunction, navFunction, shouldCreateNewVersion);
  }
}
