import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { AGGREGATED_EVALUATION_TYPES } from 'src/app/components/analyses/aggregate-evaluations/perform-aggregate-evaluations/perform-aggregate-evaluations.component';
import { SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { DeleteDialogData } from 'src/app/components/dialogs/delete-item-dialog/delete-item-dialog.component';
import { CreditsDto } from 'src/app/data-transfer/entities/credits-dto';

@Injectable({ providedIn: 'root' })
export class AggregatedEvaluationsTextDataUtil {

  constructor(private translateService: TranslateService) { }

  getAnalysisTypes(): { key: number, value: string }[] {
    return [{
      key: AGGREGATED_EVALUATION_TYPES.RECYCLABILITY,
      value: this.translateService.instant('dataEvaluation.evaluationType.rec')
    },
    {
      key: AGGREGATED_EVALUATION_TYPES.LIFE_CYCLE,
      value: this.translateService.instant('dataEvaluation.evaluationType.lca')
    },
    {
      key: AGGREGATED_EVALUATION_TYPES.MATERIAL_MASS,
      value: this.translateService.instant('dataEvaluation.evaluationType.mass')
    }];
  }

  getIncorrectInputDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('dataEvaluation.performEvaluations.errors.missingData')],
      icon: 'error'
    };
  }

  getNoEvalTypeSelectedDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('dataEvaluation.performEvaluations.errors.missingEvalType')],
      icon: 'error'
    };
  }

  getRecNotPossibleForSomePackagingUnitsDialogData(packagingIds: number[]): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.information'),
      messages: [this.translateService.instant('dataEvaluation.performEvaluations.messages.recImpossible',
        { packagingIds: packagingIds.join(', ') })],
      icon: 'warning'
    };
  }

  getLcaNotPossibleForSomePackagingUnitsDialogData(packagingIds: number[]): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.information'),
      messages: [this.translateService.instant('dataEvaluation.performEvaluations.messages.lcaImpossible',
        { packagingIds: packagingIds.join(', ') })],
      icon: 'warning'
    };
  }

  getAnalysisNotPossibleForAnyPackagingUnitsDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('dataEvaluation.performEvaluations.errors.evalImpossible')],
      icon: 'error'
    };
  }

  getNoCountriesRelevantForEvaluationDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('dataEvaluation.performEvaluations.errors.countriesError')],
      icon: 'error'
    };
  }

  getNoEvaluationsPossibleDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('dataEvaluation.performEvaluations.errors.cancelled')],
      icon: 'error'
    };
  }

  getEvaluationCostDialogData(cost: CreditsDto): SimpleDialogData {
    const messages : string[] = [];
    console.log(cost)
    if(cost.lcaCredits >0) {
      messages.push(this.translateService.instant('dataEvaluation.performEvaluations.messages.evalCostLCA', { count: cost.lcaCredits }));
    }
    if(cost.recyclabilityCredits >0) {
      messages.push(this.translateService.instant('dataEvaluation.performEvaluations.messages.evalCostRecyclability',
      { count: cost.recyclabilityCredits }));
    }

    if(cost.lcaCredits <= 0 && cost.recyclabilityCredits <= 0) {
      messages.push(this.translateService.instant('dataEvaluation.performEvaluations.messages.evalCost', { count: 0 }));
    }
    return {
      title: this.translateService.instant('common.text.information'),
      messages: messages,
      icon: 'info'
    };
  }

  getCreditsInsufficientDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('dataEvaluation.performEvaluations.errors.creditsInsufficient')],
      icon: 'error'
    };
  }

  getEvaluationErrorDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('dataEvaluation.performEvaluations.errors.backendError')],
      icon: 'error'
    };
  }

  getNoneSelectedLabel() {
    return this.translateService.instant('dataEvaluation.performEvaluations.errors.noneSelected');
  }

  getDeleteEvalConfirmationDialogData(): DeleteDialogData {
    return {
      dialogHeader: this.translateService.instant('dataEvaluation.viewEvaluations.delete.dialogHeader'),
      dialogText: this.translateService.instant('dataEvaluation.viewEvaluations.delete.dialogText')
    };
  }
}
