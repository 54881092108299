import { ComponentTypeService } from './../../navigation/services/component-type-service';
import { ComponentHandler } from 'src/app/services/component-services/component-handler';
import { DictionaryHandler } from './../dictionary';
import { RemovabilityConditionDto } from './../../data-transfer/entities/removability-condition-dto';
import { PackagingComponentPrototype, ComponentTableInfo } from './packaging-component-prototype';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LabelEntryDto } from 'src/app/data-transfer/entities/component-entities/label-entry-dto';
import { SleeveEntryDto } from 'src/app/data-transfer/entities/component-entities/sleeve-entry-dto';
import { DecorationDialogComponent, DecorationDialogData } from 'src/app/components/dialogs/decoration-dialog/decoration-dialog.component';
import { InMoldLabelEntryDto } from 'src/app/data-transfer/entities/component-entities/in-mold-label-entry-dto';
import { PackagingComponentTypesEnum } from '../packaging-component-types-enum';
import { CountriesService } from 'src/app/navigation/services/countries-service';
import { LabelDto } from 'src/app/data-transfer/entities/component-entities/label-dto';
import { InMoldLabelDto } from 'src/app/data-transfer/entities/component-entities/in-mold-label-dto';
import { SleeveDto } from 'src/app/data-transfer/entities/component-entities/sleeve-dto';
import { DecorationEntryDto } from 'src/app/data-transfer/entities/component-entities/decoration-entry-dto';
import { ComponentSubtypeDto } from 'src/app/data-transfer/entities/component-subtype-dto';
import { DecorationDto } from 'src/app/data-transfer/entities/component-entities/decoration-dto';

export const DECORATION_NAME = 'decoration';

export class DecorationComponent extends PackagingComponentPrototype {

  constructor(
    protected dictionaryHandler: DictionaryHandler,
    private decorationSubtypes: ComponentSubtypeDto[],
    private removabilityConditions: RemovabilityConditionDto[],
    private countriesService: CountriesService,
    private componentTypeService: ComponentTypeService
  ) {
    super(dictionaryHandler);
  }

  getComponentTableInfo(translate: (text: string, params?: any) => string): ComponentTableInfo {
    return {
      id: PackagingComponentTypesEnum.Decoration,
      label: DECORATION_NAME,
      text: translate('component.decoration'),
      columns: [
        {
          id: 'packagingComponentSubtypeName',
          text: translate('component.fields.type', { ofComponent: translate('component.fields.ofDecoration') })
        },
        { id: 'manufacturer', text: translate('component.fields.manufacturer') },
        { id: 'articleName', text: translate('component.fields.articleNumber') },
        { id: 'manifestationOverview', text: translate('component.fields.mainMaterialLayer') },
        { id: 'printingCoverage', text: translate('component.fields.printingCoverage') },
        { id: 'removedByConsumerValue', text: translate('component.fields.removalForDisposal') }
      ]
    };
  }

  wrapComponentInComponentEntry(componentDto: LabelDto | SleeveDto | InMoldLabelDto): LabelEntryDto | SleeveEntryDto | InMoldLabelEntryDto {
    let returnEntry;
    if (this.componentTypeService.isDecorationOfTypeLabel(componentDto.packagingComponentSubtypeId)) {
      returnEntry = new LabelEntryDto();
      returnEntry.isAdhesiveUsed = false;
    } else if (this.componentTypeService.isDecorationOfTypeSleeve(componentDto.packagingComponentSubtypeId)) {
      returnEntry = new SleeveEntryDto();
    } else if (this.componentTypeService.isDecorationOfTypeInMold(componentDto.packagingComponentSubtypeId)) {
      returnEntry = new InMoldLabelEntryDto();
    } else {
      throw new Error("Decoration type not recognized");
    }
    returnEntry.removedBeforeUsage = false;
    returnEntry.removedByConsumer = false;
    returnEntry.underlyingComponent = componentDto;
    returnEntry.surfaceRatio = 0;
    return returnEntry;
  }

  getFormFromComponentEntries(
    decorationEntryDtos: DecorationEntryDto[], handler: ComponentHandler, formBuilder: FormBuilder, isTracked: boolean
  ): FormGroup {
    return formBuilder.group({
      packagingComponents: formBuilder.array(decorationEntryDtos.map(decorationEntryDto =>
        this.getFormFromSingleComponentEntry(decorationEntryDto, handler, formBuilder, isTracked)))
    });
  }

  getFormFromSingleComponentEntry(
    decorationEntryDto: DecorationEntryDto, handler: ComponentHandler, formBuilder: FormBuilder, isTracked: boolean
  ): FormGroup {
    const decorationDto = decorationEntryDto.underlyingComponent;
    const isLabel = this.componentTypeService.isDecorationOfTypeLabel(decorationDto.packagingComponentSubtypeId);
    if (decorationDto.packagingComponentSubtypeId == null) { throw new Error('DecorationComponent: unable to get decoration type ID'); }
    const baseForm = super.createBaseForm(decorationDto, formBuilder, this.decorationSubtypes,
      isTracked || decorationEntryDto.hasInternalTracking);
    const decorationFormFroup: FormGroup = formBuilder.group({
      ...baseForm.controls,
      entryId: [decorationEntryDto.entryId],
      isAdhesiveUsed: [isLabel ? ((decorationEntryDto as LabelEntryDto).isAdhesiveUsed ?? null) : false, [Validators.required]],
      removabilityConditionId: isLabel ? [(decorationEntryDto as LabelEntryDto).removabilityConditionId] : null,
      surfaceRatio: [(decorationEntryDto.surfaceRatio && handler.multiplyWithHundred(decorationEntryDto.surfaceRatio)) ?? null,
      [Validators.required, Validators.min(0), Validators.max(100)]],
      removedBeforeUsage: [decorationEntryDto.removedBeforeUsage ?? null, [Validators.required]],
      removedByConsumer: [decorationEntryDto.removedByConsumer ?? null, [Validators.required]],
      manifestationOverview: [super.createManifestationOverview(decorationDto.multiMaterial?.layers ?? [])],
      manufacturingCountryName: [this.countriesService.getCountryNameByCode(decorationDto.manufacturingCountry ?? '')],
      isAdhesiveUsedValue: [super.booleanDictionaryKeyToValue(
        (decorationEntryDto as LabelEntryDto).isAdhesiveUsed ?? false)],
      removabilityConditionName: isLabel && (decorationEntryDto as LabelEntryDto).removabilityConditionId ?
        [super.getRemovabilityConditionName(
          (decorationEntryDto as LabelEntryDto).removabilityConditionId, this.removabilityConditions)] : '',
      removedByConsumerValue: [super.booleanDictionaryKeyToValue(decorationEntryDto.removedByConsumer ?? false)],
      hasInternalTracking: [decorationEntryDto.hasInternalTracking],
      errorCount: [0],
      multiMaterial: decorationDto.multiMaterial,
      printingCoverage: [(decorationDto.printingCoverage && handler.multiplyWithHundred(decorationDto.printingCoverage)) ?? null,
      [Validators.required, Validators.min(0), Validators.max(100)]],
      isRigidComponent: [decorationDto.isRigidComponent]
    });
    super.setErrorCountInPackagingComponent(decorationFormFroup);
    return decorationFormFroup;
  }

  getEmptyComponentEntry(): DecorationEntryDto {
    const decorationEntry = new DecorationEntryDto();
    decorationEntry.underlyingComponent = new DecorationDto();
    decorationEntry.underlyingComponent.packagingComponentSubtypeId = this.decorationSubtypes[0].id;
    return decorationEntry;
  }

  getComponentEntriesFromForm(formValues: any): DecorationEntryDto[] {
    const decorations: DecorationEntryDto[] = [];
    formValues.forEach((decorationGroup: any) => {
      decorations.push(this.getSingleComponentEntryFromForm(decorationGroup, false));
    });
    return decorations;
  }

  getSingleComponentEntryFromForm(decorationFormValue: any, forInternalUse: boolean): LabelEntryDto | SleeveEntryDto | InMoldLabelEntryDto {
    const isSleeve = this.componentTypeService.isDecorationOfTypeSleeve(decorationFormValue?.packagingComponentSubtypeId);
    const isInMoldLabel = this.componentTypeService.isDecorationOfTypeInMold(decorationFormValue?.packagingComponentSubtypeId);

    let decorationEntry;
    if (isSleeve) { decorationEntry = new SleeveEntryDto(); decorationEntry.underlyingComponent = new SleeveDto(); }
    else if (isInMoldLabel) { decorationEntry = new InMoldLabelEntryDto(); decorationEntry.underlyingComponent = new InMoldLabelDto(); }
    else { decorationEntry = new LabelEntryDto(); decorationEntry.underlyingComponent = new LabelDto(); }

    this.setComponentEntryDataFromForm(decorationEntry, decorationFormValue, forInternalUse);
    decorationEntry.underlyingComponent = this.getSingleComponentFromForm(decorationFormValue, forInternalUse);
    return decorationEntry;
  }

  private setComponentEntryDataFromForm(
    decorationEntry: LabelEntryDto | SleeveEntryDto | InMoldLabelEntryDto, decorationFormValue: any, forInternalUse: boolean
  ) {
    decorationEntry.entryId = decorationFormValue?.entryId ?? -1;
    decorationEntry.hasInternalTracking = decorationFormValue?.hasInternalTracking;
    decorationEntry.removedBeforeUsage = decorationFormValue?.removedBeforeUsage ?? null;
    decorationEntry.removedByConsumer = decorationFormValue?.removedByConsumer ?? null;
    const surfaceRatioValue = decorationFormValue?.surfaceRatio;
    decorationEntry.surfaceRatio = forInternalUse ? surfaceRatioValue : surfaceRatioValue / 100;
    if (decorationEntry instanceof LabelEntryDto) {
      decorationEntry.isAdhesiveUsed = decorationFormValue?.isAdhesiveUsed ?? null;
      decorationEntry.removabilityConditionId = decorationFormValue?.removabilityConditionId;
    }
  }

  getSingleComponentFromForm(
    decorationFormValue: any, forInternalUse: boolean, component?: LabelDto | SleeveDto | InMoldLabelDto
  ): DecorationDto {
    const decorationDto = component ?? new DecorationDto();
    decorationDto.id = decorationFormValue?.id;
    decorationDto.versionName = decorationFormValue?.newVersionName;
    decorationDto.packagingComponentSubtypeId = decorationFormValue?.packagingComponentSubtypeId;
    decorationDto.comment = decorationFormValue?.comment;
    decorationDto.manufacturer = decorationFormValue?.manufacturer;
    decorationDto.manufacturingCountry = decorationFormValue?.manufacturingCountry;
    decorationDto.articleName = decorationFormValue?.articleName;
    decorationDto.articleNumber = decorationFormValue?.articleNumber;
    decorationDto.gtin = decorationFormValue?.gtin;
    decorationDto.printingCoverage = forInternalUse ? decorationFormValue?.printingCoverage :
      decorationFormValue?.printingCoverage / 100;
    decorationDto.length = decorationFormValue?.length;
    decorationDto.width = decorationFormValue?.width;
    decorationDto.height = decorationFormValue?.height;
    decorationDto.multiMaterial = decorationFormValue?.multiMaterial;
    decorationDto.containsNearInfraredBarrier = decorationFormValue?.containsNearInfraredBarrier ?? null;
    if (decorationDto.multiMaterial) {
      decorationDto.multiMaterial.totalWeight = decorationFormValue?.totalWeight;
      decorationDto.multiMaterial.totalGrammage = decorationFormValue?.totalGrammage;
    }
    return decorationDto;
  }

  getDialogType() { return DecorationDialogComponent; }

  getComponentSubtypes(): ComponentSubtypeDto[] { return this.decorationSubtypes; }

  setComponentSpecificDialogData(dialogData: DecorationDialogData) {
    dialogData.removabilityConditions = this.removabilityConditions;
  }

  setComponentSpecificTableData(formGroup: FormGroup): void {
    formGroup.controls.removedByConsumerValue.patchValue(
      super.booleanDictionaryKeyToValue(formGroup.controls.removedByConsumer.value) ?? false);
    formGroup.controls.manifestationOverview.patchValue(
      super.createManifestationOverview((formGroup.controls.multiMaterial as FormGroup).value.layers ?? []));
  }
}
