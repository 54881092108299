<div [formGroup]="form" [ngClass]="isIndependentComponent ? 'grid-container-double' : 'grid-container'">
  <mat-card class="grid-item">
    <mat-card-content class="inner-container">

      <mat-form-field class="form-field-wide"
        matTooltip="{{ 'component.fields.tooltips.typeOfComponent' | translate }}">
        <mat-label class="label-large">
          {{ 'component.fields.type' | translate: {ofComponent: componentTypeSelectionLabel} }}
        </mat-label>
        <mat-select required formControlName="packagingComponentSubtypeId"
          (selectionChange)="onComponentTypeChange($event.value)">
          <mat-option *ngFor="let componentSubtype of componentSubtypes" [value]="componentSubtype.id">
            {{componentSubtype.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls.packagingComponentSubtypeId.errors">
          <span *ngIf="form.controls.packagingComponentSubtypeId.errors.required">
            {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field-wide" matTooltip="{{ 'component.fields.tooltips.articleName' | translate }}">
        <mat-label class="label-large">{{ 'component.fields.articleName' | translate }}</mat-label>
        <input matInput formControlName="articleName" type="text" autocomplete="off">
      </mat-form-field>

      <mat-form-field class="form-field-wide" matTooltip="{{ 'component.fields.tooltips.articleNumber' | translate }}">
        <mat-label class="label-large">{{ 'component.fields.articleNumber' | translate }}
        </mat-label>
        <input matInput formControlName="articleNumber" type="text" autocomplete="off">
      </mat-form-field>

      <mat-form-field class="form-field-wide" matTooltip="{{ 'component.fields.tooltips.gtin' | translate }}">
        <mat-label class="label-large">{{ 'component.fields.gtin' | translate }}</mat-label>
        <input matInput formControlName="gtin" type="text" autocomplete="off">
        <mat-error *ngIf="form.controls.gtin.errors">
          <span *ngIf="form.controls.gtin.errors.gtinError">
            {{ 'dataManagement.userData.userDialog.validation.gtinError' | translate }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field-wide"
        matTooltip="{{ 'component.fields.tooltips.printingCoverage' | translate }}">
        <mat-label class="label-large">{{ 'component.fields.printingCoverage' | translate }}
        </mat-label>
        <input matInput required type="number" formControlName="printingCoverage" min="0" max="100" autocomplete="off">
        <mat-error *ngIf="form.controls.printingCoverage.errors">
          <span *ngIf="form.controls.printingCoverage.errors.required">
            {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
          </span>
          <span *ngIf="form.controls.printingCoverage.errors.min">
            {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: '0%'} }}
          </span>
          <span *ngIf="form.controls.printingCoverage.errors.max">
            {{ 'dataManagement.userData.userDialog.validation.valueCannotBeGreaterThan' | translate: {value: '100%'} }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="isDecoration && !isIndependentComponent" class="form-field-wide"
        matTooltip="{{ 'component.fields.tooltips.surfaceRatio' | translate }}">
        <mat-label class="label-large">{{ 'component.fields.surfaceRatio' | translate }}</mat-label>
        <button mat-icon-button matSuffix (click)="showSurfaceRatioInfo = !showSurfaceRatioInfo" type="button"
          cdkOverlayOrigin #surfaceRatioTrigger="cdkOverlayOrigin" color="primary">
          <mat-icon>info</mat-icon>
        </button>
        <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="surfaceRatioTrigger"
          cdkConnectedOverlayHasBackdrop="true" (backdropClick)="showSurfaceRatioInfo=false"
          [cdkConnectedOverlayOpen]="showSurfaceRatioInfo">
          <app-surface-ratio-info></app-surface-ratio-info>
        </ng-template>
        <input required type="number" matInput formControlName="surfaceRatio" min="0" max="100" autocomplete="off">
        <mat-error *ngIf="form.controls.surfaceRatio.errors">
          <span *ngIf="form.controls.surfaceRatio.errors.required">
            {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
          </span>
          <span *ngIf="form.controls.surfaceRatio.errors.min">
            {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: '0%'} }}
          </span>
          <span *ngIf="form.controls.surfaceRatio.errors.max">
            {{ 'dataManagement.userData.userDialog.validation.valueCannotBeGreaterThan' | translate: {value: '100%'} }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="!isDecoration" class="form-field-wide"
        matTooltip="{{ 'component.fields.tooltips.flexibility' | translate }}">
        <mat-label class="label-large">
          {{ 'component.fields.flexibility' | translate }}
        </mat-label>
        <mat-select formControlName="isRigidComponent" required>
          <mat-option *ngFor="let flexType of flexibilityDictionary" [value]="flexType.key">
            {{flexType.value}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls.isRigidComponent.errors">
          <span *ngIf="form.controls.isRigidComponent.errors.required">
            {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
          </span>
        </mat-error>
      </mat-form-field>

      <app-tri-state-selector formControlName="containsNearInfraredBarrier"
        [options]="nirBarrierDictionary"
        label="{{ 'component.fields.containsNearInfraredBarrier' | translate }}"
        matTooltip="{{ 'component.fields.tooltips.containsNearInfraredBarrier' | translate }}">
      </app-tri-state-selector>

      <mat-form-field *ngIf="isMainBody" class="form-field-wide"
        matTooltip="{{ 'component.fields.tooltips.fillingVolume' | translate }}">
        <mat-label class="label-large">
          {{ 'component.fields.fillingVolume' | translate }}
        </mat-label>
        <input matInput formControlName="volume" min="0.001" type="number" autocomplete="off">
        <mat-error *ngIf="form.controls.volume.errors">
          <span *ngIf="form.controls.volume.errors.min">
            {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: minimalLength}
            }}
          </span>
        </mat-error>
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <mat-card class="grid-item">
    <mat-card-content class="inner-container">
      <mat-form-field class="form-field-wide" matTooltip="{{ 'component.fields.tooltips.manufacturer' | translate }}">
        <mat-label class="label-large">{{ 'component.fields.manufacturer' | translate }}
        </mat-label>
        <input matInput formControlName="manufacturer" type="text" autocomplete="off">
      </mat-form-field>

      <mat-form-field class="form-field-wide"
        matTooltip="{{ 'component.fields.tooltips.manufacturingCountry' | translate }}">
        <mat-label class="label-large">{{ 'component.fields.manufacturingCountry' | translate }}
        </mat-label>
        <mat-select formControlName="manufacturingCountry" required>
          <mat-option *ngFor="let country of manufacturingCountries" [value]="country.code">
            {{country.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls.manufacturingCountry.errors">
          <span *ngIf="form.controls.manufacturingCountry.errors.required">
            {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="isIndependentComponent" class="form-field-wide"
        matTooltip="{{ 'component.fields.tooltips.countriesOfDistribution' | translate }}">
        <mat-label class="label-large">
          {{ 'component.fields.countriesOfDistribution' | translate }}
        </mat-label>
        <mat-select class="chip-list-select" formControlName="distributionCountries" multiple>
          <mat-option *ngFor="let country of distributionCountries" [value]="country.code">
            {{ country.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls.distributionCountries.errors">
          <span *ngIf="form.controls.distributionCountries.errors.required">
            {{ 'dataManagement.userData.userDialog.validation.atLeastOneSelectionRequired' | translate }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field-wide" matTooltip="{{ 'component.fields.tooltips.length' | translate }}">
        <mat-label class="label-large">
          {{ 'component.fields.length' | translate }}
        </mat-label>
        <input matInput type="number" formControlName="length" autocomplete="off">
        <mat-error *ngIf="form.controls.length.errors">
          <span *ngIf="form.controls.length.errors.min">
            {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: minimalLength}
            }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field-wide" matTooltip="{{ 'component.fields.tooltips.width' | translate }}">
        <mat-label class="label-large">
          {{ 'component.fields.width' | translate }}
        </mat-label>
        <input matInput type="number" formControlName="width" autocomplete="off">
        <mat-error *ngIf="form.controls.width.errors">
          <span *ngIf="form.controls.width.errors.min">
            {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: minimalLength}
            }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field-wide" matTooltip="{{ 'component.fields.tooltips.height' | translate }}">
        <mat-label class="label-large">
          {{ 'component.fields.height' | translate }}
        </mat-label>
        <input matInput type="number" formControlName="height" autocomplete="off">
        <mat-error *ngIf="form.controls.height.errors">
          <span *ngIf="form.controls.height.errors.min">
            {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: minimalLength}
            }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="!isIndependentComponent && !isInlay && !isMainBody" class="form-field-wide"
        matTooltip="{{ 'component.fields.tooltips.removalBeforeUsage' | translate }}">
        <mat-label class="label-large">{{ 'component.fields.removalBeforeUsage' | translate }}
        </mat-label>
        <mat-select required formControlName="removedBeforeUsage" required
          (selectionChange)="onRemovedBeforeUsageChange($event.value)">
          <mat-option *ngFor="let removed of componentRemovedDictionary" [value]="removed.key">
            {{removed.value}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls.removedBeforeUsage.errors">
          <span *ngIf="form.controls.removedBeforeUsage.errors.required">
            {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="!isIndependentComponent && !isInlay && !isMainBody" class="form-field-wide"
        matTooltip="{{ 'component.fields.tooltips.removalForDisposal' | translate }}">
        <mat-label class="label-large">{{ 'component.fields.removalForDisposal' | translate }}
        </mat-label>
        <mat-select formControlName="removedByConsumer" required>
          <mat-option *ngFor="let removed of componentRemovedDictionary" [value]="removed.key">
            {{removed.value}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls.removedByConsumer.errors">
          <span *ngIf="form.controls.removedByConsumer.errors.required">
            {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="((isDecoration && form.controls.isAdhesiveUsed) || isInlay) && !isIndependentComponent"
        class="form-field-wide" matTooltip="{{ 'component.fields.tooltips.adhesivesUsed' | translate }}">
        <mat-label class="label-large">{{ 'component.fields.adhesivesUsed' | translate }}</mat-label>
        <mat-select formControlName="isAdhesiveUsed" (selectionChange)="onAdhesivesOptionChange($event.value)" required>
          <mat-option *ngFor="let used of adhesivesUsedDictionary" [value]="used.key">
            {{used.value}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls.isAdhesiveUsed?.errors">
          <span *ngIf="form.controls.isAdhesiveUsed.errors?.required">
            {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field
        *ngIf="((isDecoration && form.controls.removabilityConditionId) || isInlay) && !isIndependentComponent"
        class="form-field-wide" matTooltip="{{ 'component.fields.tooltips.removabilityCondition' | translate }}">
        <mat-label class="label-large">{{ 'component.fields.removabilityCondition' | translate }}
        </mat-label>
        <mat-select formControlName="removabilityConditionId">
          <mat-option *ngFor="let condition of removabilityConditions" [value]="condition.id">
            {{condition.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls.removabilityConditionId?.errors">
          <span *ngIf="form.controls.removabilityConditionId?.errors?.required">
            {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="isInlay" class="form-field-wide"
        matTooltip="{{ 'component.fields.tooltips.attachedToMainBody' | translate }}">
        <mat-label class="label-large">{{ 'component.fields.attachedToMainBody' | translate }}
        </mat-label>
        <mat-select required formControlName="attachedToMainBody">
          <mat-option *ngFor="let attached of attachedToMainBodyDictionary" [value]="attached.key">
            {{attached.value}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls.attachedToMainBody.errors">
          <span *ngIf="form.controls.attachedToMainBody.errors.required">
            {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field-wide">
        <mat-label class="label-large">
          {{ 'component.fields.totalGrammage' | translate: {ofComponent: componentTypeSelectionLabel} }}
          {{ 'component.fields.totalGrammageUnit' | translate }}
        </mat-label>
        <input matInput type="number" formControlName="totalGrammage" autocomplete="off">
        <mat-error *ngIf="form.controls.totalGrammage.errors">
          <span *ngIf="form.controls.totalGrammage.errors.min">
            {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: minimalLength}
            }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field-wide">
        <mat-label class="label-large">
          {{ 'component.fields.totalWeight' | translate: {ofComponent: componentTypeSelectionLabel} }}
          {{ 'component.fields.totalWeightUnit' | translate }}
        </mat-label>
        <input matInput type="number" formControlName="totalWeight" autocomplete="off">
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</div>
