import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { UserApiService } from 'src/app/data-transfer/services/user-api-service';
import { ChangePasswordDialogComponent, PasswordDialogData } from './../../../components/dialogs/change-password-dialog/change-password-dialog.component';
import { Subscription } from 'rxjs';
import { SimpleAlertDialogComponent, SimpleDialogData } from './../../../components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './../../../services/auth-service';
import { PathResolveService } from './../../../services/path-resolve.service';
import { Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';
import { CreditsService } from 'src/app/services/credits-service';
import { ColorThemeService, COLOR_THEME_DARK, COLOR_THEME_LIGHT } from '../../services/color-theme-service';
import { MatDialog } from '@angular/material/dialog';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { DialogActions } from 'src/app/model/dictionary';
import { PROJECT_IMAGES_PATH } from 'src/app/model/paths';
import { LanguageHandler } from 'src/app/util/language-handler';
import { EnvironmentHelperService } from 'src/app/services/environment-helper.service';
import { CreditsDto } from 'src/app/data-transfer/entities/credits-dto';
import {ContactInfoDialogComponent} from 'src/app/components/dialogs/contact-info-dialog/contact-info-dialog.component'
@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})
export class MainNavComponent implements OnDestroy {

  @Input() isMinimalLayout = false;

  username = '';
  countryCodes: string[] = [];
  isDarkTheme = false;
  creditsLoaded!: Promise<boolean>;
  isAdmin: boolean;
  isValidator: boolean;
  isContractSigned: boolean;
  currentUserId: number;
  packagingPart = PackagingPart;
  imageSource = '';

  private languageHandler: LanguageHandler;
  private creditsChangeSubscription: Subscription;
  private dialogSubscription?: Subscription;
  private changePassSubscription?: Subscription;
  private signOutSubscription?: Subscription;
  private themeSubscription?: Subscription;

  colorThemes = [
    { id: 1, label: COLOR_THEME_LIGHT, name: this.translateService.instant('dataManagement.userData.texts.colorTheme.light') },
    { id: 2, label: COLOR_THEME_DARK, name: this.translateService.instant('dataManagement.userData.texts.colorTheme.dark') }];

  constructor(
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private creditsService: CreditsService,
    private cdr: ChangeDetectorRef,
    private userApiService: UserApiService,
    private colorThemeService: ColorThemeService,
    private environmentService: EnvironmentHelperService
  ) {
    this.username = this.authService.getSignedInUsername();
    this.currentUserId = authService.getUserId();
    this.isAdmin = this.authService.isUserAdmin();
    this.isValidator = this.authService.isUserValidator();
    this.isContractSigned = this.authService.isContractSigned();

    this.creditsService.setCreditsCount();
    this.creditsChangeSubscription = this.creditsService.creditsCountChange
      .subscribe(_ => { this.cdr.detectChanges(); });

    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
      this.imageSource = `${PROJECT_IMAGES_PATH}logo/logo_paco${this.isDarkTheme ? '_light' : '_dark'}.svg`;
    });
    this.languageHandler = new LanguageHandler(this.translateService);
    this.countryCodes = this.languageHandler.getLanguageCodes();
  }

  get creditsCount(): CreditsDto {
    let credits = new CreditsDto();
    credits.lcaCredits = this.creditsService.areLcaCreditsInfinite()? -1 : this.creditsService.creditsCount.lcaCredits;
    credits.recyclabilityCredits = this.creditsService.areRycyclingCreditsInfinite()? -1 : this.creditsService.creditsCount.recyclabilityCredits;
    return credits;
  }

  clickHome() {
    this.router.navigate([PathResolveService.getStartPath(true)]);
  }

  clickPackagingSystemHome() {
    const path = PathResolveService.getPackagingSystemHomepagePath(true);
    this.router.navigate([path]);
  }

  clickPackagingUnitHome() {
    const path = PathResolveService.getPackagingUnitHomepagePath(true);
    this.router.navigate([path]);
  }

  clickComponentsHome() {
    this.router.navigate([PathResolveService.getComponentHomepagePath(true)]);
  }

  clickMaterialHome() {
    const path = PathResolveService.getMaterialHomepagePath(true);
    this.router.navigate([path]);
  }

  clickTags() {
    const path = PathResolveService.getTagsManagementPath(true);
    this.router.navigate([path]);
  }

  clickSignOut() {
    this.signOut();
  }

  private async signOut() {
    this.signOutSubscription = this.authService.signOut().subscribe(_ => this.router.navigateByUrl('/login'));
  }

  clickContactInfo() {
    const dialogConfig = getDialogConfig(null, '800px');
    const dialogRef = this.dialog.open(ContactInfoDialogComponent, dialogConfig);
  }

  clickChangePassword() {
    const dialogData = new PasswordDialogData(this.currentUserId, this.username, this.isAdmin);
    const dialogConfig = getDialogConfig(dialogData, '600px');
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      this.changePassSubscription = this.userApiService.changePassword(result.data, true).subscribe(
        _ => { },
        error => {
          const errorData: SimpleDialogData = { title: 'Error', messages: [error.error], icon: 'error' };
          const errorDialogConfig = getDialogConfig(errorData, '600px');
          this.dialog.open(SimpleAlertDialogComponent, errorDialogConfig);
        });
    });
  }

  setColorTheme(theme: number) {
    this.colorThemeService.colorTheme = this.colorThemes.find(x => x.id === theme)?.label ?? COLOR_THEME_LIGHT;
  }

  setLanguage(countryCode: string) {
    this.languageHandler.setLanguage(countryCode);
  }

  getButtonClass(path: string) {
    const isActiveRoute =
      this.router.isActive(path, { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' });
    return isActiveRoute ? this.isDarkTheme ? 'active-dark-theme' : 'active-light-theme' : '';
  }

  getPackagingPartImage(packagingPart: number) {
    let imagePath = `${PROJECT_IMAGES_PATH}icons/`;
    switch (packagingPart) {
      case PackagingPart.System: imagePath = imagePath.concat('system'); break;
      case PackagingPart.Unit: imagePath = imagePath.concat('unit'); break;
      case PackagingPart.Component: imagePath = imagePath.concat('component'); break;
      case PackagingPart.Material: imagePath = imagePath.concat('material'); break;
      default: break;
    }
    if (this.isDarkTheme) { imagePath = imagePath.concat('_dark'); }
    return imagePath.concat('.svg');
  }

  ngOnDestroy(): void {
    this.creditsChangeSubscription?.unsubscribe();
    this.dialogSubscription?.unsubscribe();
    this.changePassSubscription?.unsubscribe();
    this.signOutSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
  }
}
