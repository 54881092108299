import { VersionDto } from 'src/app/data-transfer/entities/version-dto';
import { ComponentHandler } from 'src/app/services/component-services/component-handler';
import { GtinCheckService } from './../../../../services/component-services/gtin-check-service';
import { AuthService } from './../../../../services/auth-service';
import { ColorThemeService } from './../../../../navigation/services/color-theme-service';
import { PackagingComponentTypesEnum } from './../../../../model/packaging-component-types-enum';
import { DictionaryHandler } from './../../../../model/dictionary';
import { ComponentTypeService } from './../../../../navigation/services/component-type-service';
import { ComponentApiService } from '../../../../data-transfer/services/component-api-service';
import { ComponentCreateUpdateParentComponent } from './../common/component-create-update-parent/component-create-update-parent.component';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, HostListener } from '@angular/core';
import { ComponentNavigationService } from 'src/app/navigation/services/navigation-services/component-navigation-service';
import { ComponentCanDeactivate } from 'src/app/services/unsaved-changes-guard';
import { FileApiService } from 'src/app/data-transfer/services/file-api-service';
import { COLOR_THEME_DARK } from 'src/app/navigation/services/color-theme-service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { InlayDto } from 'src/app/data-transfer/entities/component-entities/inlay-dto';
import { INLAY_NAME } from 'src/app/model/components/inlay-component';

@Component({
  selector: 'app-create-update-inlay',
  templateUrl: './create-update-inlay.component.html',
  styleUrls: ['./create-update-inlay.component.scss']
})
export class CreateUpdateInlayComponent extends ComponentCreateUpdateParentComponent
  implements OnInit, ComponentCanDeactivate {

  inlay!: InlayDto;

  constructor(
    protected componentHandler: ComponentHandler,
    protected dialog: MatDialog,
    protected spinner: NgxSpinnerService,
    protected componentApiService: ComponentApiService,
    protected navigationService: ComponentNavigationService,
    protected componentTypeService: ComponentTypeService,
    protected dictionaryHandler: DictionaryHandler,
    protected fileApiService: FileApiService,
    protected authService: AuthService,
    protected gtinCheckService: GtinCheckService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private colorThemeService: ColorThemeService
  ) {
    super(componentHandler, dialog, spinner, componentApiService, navigationService,
      componentTypeService, dictionaryHandler, fileApiService, authService, gtinCheckService);
  }

  ngOnInit(): void {
    this.callerId = PackagingComponentTypesEnum.Inlay;
    this.componentType = this.componentTypeService.getComponentTypeDtoByName(INLAY_NAME);
    this.componentSubtypes = this.componentHandler.getComponentSubtypesSelection(this.callerId);

    this.routeDataSubscription = this.route.data.subscribe(data => {
      if (data.component) {
        this.inlay = data.component;
        this.historyVersions = [{
          id: data.component.id,
          versionNumber: data.component.version,
          versionName: data.component.versionName,
          creationTimestamp: data.component.creationTimestamp,
          isCurrentVersion: data.component.isCurrentVersion,
          packagingComponentCategoryId: PackagingComponentTypesEnum.Inlay
        }];
        this.currentDirectoryId = data.component.directoryId;
        if (this.form) {
          window.location.reload();
          super.initComponent(this.inlay, this.route);
        }
      } else {
        this.inlay = new InlayDto();
        this.inlay.images = [];
        this.inlay.documents = [];
      }
      if (data.permissions) {
        this.permissions = data.permissions;
      }
      if (data.changedMaterialIds && data.changedMaterialIds.length > 0) {
        super.setChangedMultiMaterials(this.inlay.multiMaterial.subMultiMaterials, data.changedMaterialIds);
      }
    });

    super.initComponent(this.inlay, this.route);
    const inlayComponent = this.componentHandler.components[this.callerId];
    this.form = inlayComponent.createBaseForm(this.inlay, this.formBuilder, this.componentSubtypes, this.isTracked);
    this.form = this.formBuilder.group({
      ...this.form.controls,
      distributionCountries: [this.inlay.distributionCountries],
      printingCoverage: [this.inlay.printingCoverage != null ? this.inlay.printingCoverage * 100 : null,
      [Validators.required, Validators.min(0), Validators.max(100)]],
    });
    this.form.controls.totalWeight.disable();
    super.initGtinCheck();

    this.setUpLockAndHeartbeat(this.inlay);
    this.disableFormIfRequired();

    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
      this.imageSource = this.componentTypeService.getComponentTypeImage(this.callerId, this.isDarkTheme);
    });
  }

  async onComponentSubmit(materialEdited: boolean) {
    this.materialEdited = materialEdited;
    this.form.markAllAsTouched();
    const inlayComponent = this.componentHandler.components[this.callerId];
    this.inlay = inlayComponent.getSingleComponentFromForm(this.form.value.getRawValue(), false, this.inlay) as InlayDto;
    // this.inlay.packagingComponentSubtypeId = this.form.controls.packagingComponentSubtypeId.value;

    super.onSubmit(this.inlay);
  }

  changeInlayVersionName(event: { historyVersion: VersionDto, newName: string }) {
    super.changeVersionName(event.historyVersion, event.newName, this.inlay);
  }

  async releaseComponent() {
    super.releaseComponent(this.inlay);
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return (this.form.pristine && !this.filesEdited) || this.isPreview || this.isUserValidator || this.isUserAdmin || this.isHistory;
  }

  handleMaterialChange(materialChange: { accepted: boolean, materialId: number }) {
    if (this.inlay.id == null) { return; }
    super.handleMaterialChangeParent(this.inlay.id, materialChange.materialId, materialChange.accepted);
  }
}
