import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParentApiService } from './parent-api-service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PackagingPartTypeDto } from '../entities/packaging-part-type-dto';
import { PackagingUnitInfoDto } from '../entities/packaging-unit-entities/packaging-unit-info-dto';
import { PackagingUnitDto } from '../entities/packaging-unit-entities/packaging-unit-dto';
import { VersionDto } from '../entities/version-dto';
import { DeletedEntitiesDto } from '../entities/deleted-entities-dto';
import { PermissionTypeDto } from '../entities/permission-type-dto';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PackagingUnitApiService extends ParentApiService {

  constructor(private http: HttpClient) { super(); }

  public getPackagingUnitTypes(): Observable<PackagingPartTypeDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-types`;
    return this.http.get<PackagingPartTypeDto[]>(url);
  }

  public getPackagingUnitTypesEnglish(): Observable<PackagingPartTypeDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-types`;
    return this.http.get<PackagingPartTypeDto[]>(url, { headers: this.acceptEnglishHeader });
  }

  public getPackagingUnitsByType(packagingUnitTypeId: number): Observable<PackagingUnitInfoDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-units/by-type?typeId=${packagingUnitTypeId}`;
    return this.http.get<PackagingUnitInfoDto[]>(url);
  }

  public getPackagingUnit(packagingUnitId: number, versionId?: number): Observable<PackagingUnitDto> {
    let url = `${environment.webApiAddress}/api/packaging-units/by-id?id=${packagingUnitId}`;
    if (versionId != null) {
      url = url.concat(`&version=${versionId}`);
    }
    return this.http.get<PackagingUnitDto>(url);
  }

  public getPackagingUnitHistoryVersions(packagingUnitId: number) {
    const url = `${environment.webApiAddress}/api/packaging-units/history?id=${packagingUnitId}`;
    return this.http.get<VersionDto[]>(url);
  }

  public putPackagingUnit(packagingUnit: PackagingUnitDto, shouldCreateNewVersion = true, stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-units?overwriteExistingVersion=${!shouldCreateNewVersion}`;
    console.log(JSON.stringify(packagingUnit));
    return this.http.put<any>(url, packagingUnit,
      { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public renamePackagingUnit(id: number, version: number, name: string, stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-units/rename-version`;
    let params = new HttpParams().set('id', id);
    params = params.set('version', version);
    params = params.set('name', name);
    return this.http.put<any>(url, {}, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public setPackagingUnitTags(id: number, tags: number[], stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-units/set-tags`;
    const params = new HttpParams().set('packagingUnitId', id);
    return this.http.put<any>(url, tags, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public addPackagingUnitTags(id: number, tags: number[], stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-units/add-tags`;
    const params = new HttpParams().set('packagingUnitId', id);
    return this.http.put<any>(url, tags, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public deletePackagingUnits(
    idsToDelete: DeletedEntitiesDto, deletePermanently: boolean, stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-units?deletePermanently=${deletePermanently}`;
    return this.http.request('delete', url, {
      body: idsToDelete,
      headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader
    });
  }

  public lockPackagingUnit(packagingUnitId: number, stayOnPageIfError: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-units/${packagingUnitId}/lock`;
    return this.http.get<any>(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public releasePackagingUnit(packagingUnitId: number, stayOnPageIfError: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-units/${packagingUnitId}/release`;
    return this.http.get<any>(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader })
      .pipe(catchError(this.handleError));
  }

  public getChangedComponentIdsInPackagingUnit(packagingUnitId: number): Observable<number[]> {
    const url = `${environment.webApiAddress}/api/packaging-units/component-changes?packagingUnitId=${packagingUnitId}`;
    return this.http.get<number[]>(url);
  }

  public acceptComponentChange(packagingUnitId: number, componentId: number, shouldCreateNewVersion = true, stayOnPageIfError?: boolean) {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingUnitId', packagingUnitId);
    params = params.set('packagingComponentId', componentId);
    params = params.set('overwriteExistingVersion', !shouldCreateNewVersion);
    const url = `${environment.webApiAddress}/api/packaging-units/adopt-component-changes`;
    return this.http.put<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public declineComponentChange(packagingUnitId: number, componentId: number, stayOnPageIfError?: boolean) {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingUnitId', packagingUnitId);
    params = params.set('packagingComponentId', componentId);
    const url = `${environment.webApiAddress}/api/packaging-units/ignore-component-changes`;
    return this.http.put<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public downloadPackagingUnitsJson(packagingUnitsToDownload: { id: number, version: number }[]): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-units/json`;
    return this.http.post<Blob>(url, packagingUnitsToDownload, { responseType: 'Blob' as 'json' });
  }

  public getLastViewedPackagingUnits(count: number): Observable<PackagingUnitInfoDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-units/latest?count=${count}`;
    return this.http.get<PackagingUnitInfoDto[]>(url);
  }

  public getPackagingUnitPermissions(packagingUnitId: number): Observable<PermissionTypeDto> {
    const url = `${environment.webApiAddress}/api/permissions/effective-packaging-unit-permissions?packagingUnitId=${packagingUnitId}`;
    return this.http.get<PermissionTypeDto>(url);
  }

  public getAllPackagingUnits(): Observable<PackagingUnitInfoDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-units`;
    return this.http.get<PackagingUnitInfoDto[]>(url);
  }

  public getFilteredPackagingUnits(startTimestamp?: string, endTimestamp?: string): Observable<PackagingUnitInfoDto[]> {
    let params: HttpParams = new HttpParams();
    if (startTimestamp) {
      params = params.set('startTimestamp', startTimestamp);
    }
    if (endTimestamp) {
      params = params.set('endTimestamp', endTimestamp);
    }
    const url = `${environment.webApiAddress}/api/packaging-units`;
    return this.http.get<PackagingUnitInfoDto[]>(url, { params });
  }

  public getPackagingUnitQuantity(
    packagingUnitId: number, year: number, month: number, countryCode: string, version?: number, stayOnPageIfError?: boolean
  ): Observable<number> {
    let params: HttpParams = new HttpParams();
    params = params.set('id', packagingUnitId);
    params = params.set('year', year);
    params = params.set('month', month);
    params = params.set('country', countryCode);
    if (version != null) {
      params = params.set('version', version);
    }
    const url = `${environment.webApiAddress}/api/packaging-units/quantity`;
    return this.http.get<number>(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params});
  }

  public setPackagingUnitQuantity(
    packagingUnitId: number, year: number, month: number, quantity: number,
    countryCode: string, version?: number, stayOnPageIfError?: boolean
  ) {
    let params: HttpParams = new HttpParams();
    params = params.set('id', packagingUnitId);
    params = params.set('year', year);
    params = params.set('month', month);
    params = params.set('quantity', quantity);
    params = params.set('country', countryCode);
    if (version != null) {
      params = params.set('version', version);
    }
    const url = `${environment.webApiAddress}/api/packaging-units/quantity`;
    return this.http.put<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public getPackagingUnitPreview(candidateId: number): Observable<PackagingUnitDto> {
    const url = `${environment.webApiAddress}/api/packaging-units/by-import-candidate?importCandidateId=${candidateId}`;
    return this.http.get<PackagingUnitDto>(url);
  }
}
