import { PackagingParentDto } from "../packaging-parent-dto";

export class PackagingSystemUpdateDto extends PackagingParentDto {
  id?: number;
  packagingSystems?: PackagingEntityInHierarchyDto[];
  packagingUnits?: PackagingEntityInHierarchyDto[];
}

export class PackagingEntityInHierarchyDto {
  id?: number;
  version?: number;
  quantity?: number;
  index?: number;
}