import { Subscription } from 'rxjs';
import { Component, Input, forwardRef, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { FillingGoodCategoryDto } from 'src/app/data-transfer/entities/filling-good-category-dto';
import { FillingGoodDto } from 'src/app/data-transfer/entities/filling-good-dto';
import { GtinCheckService } from 'src/app/services/component-services/gtin-check-service';
import { CountryDto } from 'src/app/data-transfer/entities/country-dto';
import { FillingGoodTypeDto } from 'src/app/data-transfer/entities/filling-good-type-dto';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { CountriesService } from 'src/app/navigation/services/countries-service';

@Component({
  selector: 'app-packaging-unit-html-template',
  templateUrl: './packaging-unit-html-template.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./packaging-unit-html-template.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PackagingUnitHtmlTemplateComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PackagingUnitHtmlTemplateComponent),
      multi: true,
    }
  ],
})
export class PackagingUnitHtmlTemplateComponent implements ControlValueAccessor, OnChanges, OnDestroy {

  @Input() assemblyCountries: CountryDto[] = [];
  @Input() distributionCountries: CountryDto[] = [];
  @Input() fillingGoodTypes: FillingGoodTypeDto[] = [];
  @Input() fillingGoodCategories: FillingGoodCategoryDto[] = [];
  @Input() fillingGoods: FillingGoodDto[] = [];
  @Input() showErrors = false;
  @Input() fillingVolume: number | undefined;

  feasibleFillingGoodCategories!: FillingGoodCategoryDto[];
  feasibleFillingGoods!: FillingGoodDto[];
  minimalValue = 0.001;

  packagingUnitForm!: FormGroup;
  maxCommentLength = 250;

  showDimensionsInfo = false;
  allDistrCountryNames = '';

  private gtinSubscription?: Subscription;
  private formSubscription?: Subscription;

  static updatePackagingUnit(formControlValues: any, packagingUnit: PackagingUnitDto) {
    if (!packagingUnit) { return; }
    packagingUnit.brandName = formControlValues.brandName;
    packagingUnit.productName = formControlValues.productName;
    packagingUnit.articleNumber = formControlValues.articleNumber;
    packagingUnit.gtin = formControlValues.gtin;

    if (formControlValues.fillingGoodType) {
      packagingUnit.fillingGoodTypeId = formControlValues.fillingGoodType;
    }
    if (formControlValues.fillingGoodCategory) {
      packagingUnit.fillingGoodCategoryId = formControlValues.fillingGoodCategory;
    }
    if (formControlValues.fillingGood) {
      packagingUnit.fillingGoodId = formControlValues.fillingGood;
    }
    packagingUnit.packagingQuantity = formControlValues.packagingQuantity === '' ? null :
      formControlValues.packagingQuantity;

    packagingUnit.packagingVolume = formControlValues.packagingVolume === '' ? null :
      formControlValues.packagingVolume;

    packagingUnit.assemblyCountry = formControlValues.assemblyCountry;
    packagingUnit.distributionCountries = formControlValues.distributionCountries;
    packagingUnit.comment = formControlValues.comment;
    packagingUnit.length = formControlValues.length;
    packagingUnit.width = formControlValues.width;
    packagingUnit.height = formControlValues.height;
  }

  constructor(
    private formBuilder: FormBuilder,
    private gtinCheckService: GtinCheckService,
    private countriesService: CountriesService
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showErrors && changes.showErrors.currentValue) {
      this.packagingUnitForm?.markAllAsTouched();
    }
    if (changes.fillingVolume && !changes.fillingVolume.firstChange) {
      this.validateVolume();
    }
  }

  get packagingForm() { return this.packagingUnitForm.controls; }

  get value() {
    return this.packagingUnitForm.value;
  }

  set value(value: PackagingUnitDto) {
    this.packagingUnitForm = this.formBuilder.group(this.getFormGroup(value));
    this.onFillingGoodTypeChange();
    this.onFillingGoodCategoryChange();
    this.gtinSubscription = this.packagingUnitForm.controls.gtin.valueChanges.subscribe(async val => {
      this.packagingUnitForm.controls.gtin.setValue(val?.trim(), { emitEvent: false });
      await this.gtinCheckService.checkGtinValidity(this.packagingUnitForm);
      this.packagingUnitForm.controls.gtin.markAsTouched();
    });
    this.formSubscription = this.packagingUnitForm.valueChanges.subscribe(_ => {
      this.onChange(this.packagingUnitForm.value);
    });
    this.allDistrCountryNames = this.getAllDistrCountryNames();
  }

  writeValue(value: PackagingUnitDto): void {
    if (value) {
      this.value = value;
    } else {
      this.packagingUnitForm.reset();
    }
  }

  onChange: any = () => { };
  onTouched: any = () => { };
  registerOnChange(fn: any): void { this.onChange = fn; }
  registerOnTouched(fn: any): void { this.onTouched = fn; }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.packagingUnitForm.disable() : this.packagingUnitForm.enable();
  }

  validate(_: AbstractControl): ValidationErrors | null {
    this.validateVolume();
    return this.packagingUnitForm.valid ? null :
      { 'app-packaging-unit-html-template': { valid: false, message: 'packaging unit data invalid' } };
  }

  private validateVolume(): void {
    const packagingVolumeControl = this.packagingUnitForm.controls.packagingVolume;
    const packagingVolume =  this.packagingUnitForm.controls.packagingVolume.value;
    if (!packagingVolume || !this.fillingVolume) { return; }
    const packagingVolumeLargerOrEqualToFilling = packagingVolume >= this.fillingVolume;
    if (!packagingVolumeLargerOrEqualToFilling) {
      packagingVolumeControl.setErrors({ volumeError: true });
    } else {
      packagingVolumeControl.setErrors(null);
    }
  }

  private getFormGroup(packagingUnit?: PackagingUnitDto) {
    return {
      brandName: [packagingUnit?.brandName ?? null, Validators.required],
      productName: [packagingUnit?.productName ?? null, Validators.required],
      articleNumber: [packagingUnit?.articleNumber ?? null],
      gtin: [packagingUnit?.gtin ?? null],
      fillingGoodType: [packagingUnit?.fillingGoodTypeId ?? null],
      fillingGoodCategory: [packagingUnit?.fillingGoodCategoryId ?? null],
      fillingGood: [packagingUnit?.fillingGoodId ?? null],
      packagingQuantity: [packagingUnit?.packagingQuantity ?? null, Validators.min(this.minimalValue)],
      packagingVolume: [packagingUnit?.packagingVolume ?? null, Validators.min(this.minimalValue)],
      assemblyCountry: [packagingUnit?.assemblyCountry ?? null],
      distributionCountries: [packagingUnit?.distributionCountries ?? []],
      comment: [packagingUnit?.comment],
      length: [packagingUnit?.length, Validators.min(this.minimalValue)],
      width: [packagingUnit?.width, Validators.min(this.minimalValue)],
      height: [packagingUnit?.height, Validators.min(this.minimalValue)],
    };
  }

  onFillingGoodTypeChange(newId?: number) {
    if (newId != null) {
      this.packagingUnitForm.get('fillingGoodCategory')?.patchValue('');
      this.packagingUnitForm.get('fillingGood')?.patchValue('');
    }
    const id = newId ?? (this.packagingUnitForm.get('fillingGoodType')?.value as number);
    this.feasibleFillingGoodCategories = this.fillingGoodCategories?.filter(x => x.typeId === id);
    this.feasibleFillingGoods = [];
  }

  onFillingGoodCategoryChange(newId?: number) {
    if (newId != null) {
      this.packagingUnitForm.get('fillingGood')?.patchValue('');
    }
    const id = newId ?? (this.packagingUnitForm.get('fillingGoodCategory')?.value as number);
    this.feasibleFillingGoods = this.fillingGoods?.filter(x => x.categoryId === id);
  }

  getAllDistrCountryNames() {
    const allDistrCountryCodes: string[] = this.packagingUnitForm.controls.distributionCountries.value;
    const allDistrCountryNames = allDistrCountryCodes.map(x => this.countriesService.getCountryNameByCode(x));
    return allDistrCountryNames.map((item) => item).join(', ');
  }

  ngOnDestroy(): void {
    this.gtinSubscription?.unsubscribe();
    this.formSubscription?.unsubscribe();
  }
}
