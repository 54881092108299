import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ParentApiService } from './parent-api-service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FileApiService extends ParentApiService {

    constructor(private http: HttpClient) {
        super();
    }

    public getImageById(imageId: number) {
        const url = `${environment.webApiAddress}/api/images?id=${imageId}`;
        return this.http.get(url, { responseType: 'blob' });
    }

    public getImageThumbnailById(imageId: number) {
        const url = `${environment.webApiAddress}/api/images/thumbnail?id=${imageId}`;
        return this.http.get(url, { responseType: 'blob' });
    }

    public putImages(images: FormData, stayOnPageIfError?: boolean): Observable<number[]> {
        const url = `${environment.webApiAddress}/api/images/upload`;
        return this.http.put<number[]>(url, images, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public getPdfById(pdfId: number) {
        const url = `${environment.webApiAddress}/api/documents?id=${pdfId}`;
        return this.http.get(url, { responseType: 'blob' });
    }

    public putDocuments(pdfs: FormData, stayOnPageIfError?: boolean): Observable<number[]> {
        const url = `${environment.webApiAddress}/api/documents/upload`;
        return this.http.put<number[]>(url, pdfs, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public saveLcaExcelFile(excel: FormData, stayOnPageIfError?: boolean) {
        const url = `${environment.webApiAddress}/api/life-cycle-data-import`;
        return this.http.post(url, excel, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    private parseErrorBlob(err: HttpErrorResponse): Observable<any> {
        const reader: FileReader = new FileReader();
    
        const obs = Observable.create((observer: any) => {
          reader.onloadend = (e) => {
            observer.error(JSON.parse(reader.result as string));
            observer.complete();
          }
        });
        reader.readAsText(err.error);
        return obs;
    }

    public putExcelUsers(formData: FormData, stayOnPageIfError?: boolean): Observable<any> {
        const url = `${environment.webApiAddress}/api/organizations/excel`;
        return this.http.put(url, formData, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, responseType: 'blob' })
            .pipe(catchError(this.parseErrorBlob));
    }

    public changePasswordsExcel(formData: FormData, stayOnPageIfError?: boolean): Observable<any> {
        const url = `${environment.webApiAddress}/api/organizations/change-passwords/excel`;
        return this.http.put(url, formData, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, responseType: 'blob' })
            .pipe(catchError(this.parseErrorBlob));
    }
}
