import { ComponentHandler } from 'src/app/services/component-services/component-handler';
import { DictionaryHandler } from './../dictionary';
import { PackagingComponentPrototype, ComponentTableInfo } from './packaging-component-prototype';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClosureEntryDto } from 'src/app/data-transfer/entities/component-entities/closure-entry-dto';
import { ClosureDialogComponent } from 'src/app/components/dialogs/closure-dialog/closure-dialog.component';
import { PackagingComponentTypesEnum } from '../packaging-component-types-enum';
import { CountriesService } from 'src/app/navigation/services/countries-service';
import { ClosureDto } from 'src/app/data-transfer/entities/component-entities/closure-dto';
import { ComponentSubtypeDto } from 'src/app/data-transfer/entities/component-subtype-dto';
import { ComponentDialogData } from 'src/app/components/dialogs/additional-packaging-components-parent-dialog/additional-packaging-components-parent-dialog.component';
import { MaterialDataHandler } from 'src/app/services/material-services/material-data-handler';

export const CLOSURE_NAME = 'closure';

export class ClosureComponent extends PackagingComponentPrototype {

  constructor(
    protected dictionaryHandler: DictionaryHandler,
    private closureSubtypes: ComponentSubtypeDto[],
    private countriesService: CountriesService
  ) {
    super(dictionaryHandler);
  }

  getComponentTableInfo(translate: (text: string, params?: any) => string): ComponentTableInfo {
    return {
      id: PackagingComponentTypesEnum.Closure,
      label: CLOSURE_NAME,
      text: translate('component.closure'),
      columns: [
        {
          id: 'packagingComponentSubtypeName',
          text: translate('component.fields.type', { ofComponent: translate('component.fields.ofClosure') })
        },
        { id: 'manufacturer', text: translate('component.fields.manufacturer') },
        { id: 'articleName', text: translate('component.fields.articleName') },
        { id: 'manifestationOverview', text: translate('component.fields.mainMaterialLayer') },
        { id: 'removedByConsumerValue', text: translate('component.fields.removalForDisposal') }
      ]
    };
  }

  wrapComponentInComponentEntry(componentDto: ClosureDto): ClosureEntryDto {
    const returnEntry = new ClosureEntryDto();
    returnEntry.removedBeforeUsage = false;
    returnEntry.removedByConsumer = false;
    returnEntry.underlyingComponent = componentDto;
    return returnEntry;
  }

  getFormFromComponentEntries(
    closureEntryDtos: ClosureEntryDto[], handler: ComponentHandler, formBuilder: FormBuilder, isTracked: boolean
  ): FormGroup {
    return formBuilder.group({
      packagingComponents: formBuilder.array(closureEntryDtos.map(closureEntryDto =>
        this.getFormFromSingleComponentEntry(closureEntryDto, handler, formBuilder, isTracked)))
    });
  }

  getFormFromSingleComponentEntry(
    closureEntryDto: ClosureEntryDto, handler: ComponentHandler, formBuilder: FormBuilder, isTracked: boolean
  ): FormGroup {
    const closureDto = closureEntryDto.underlyingComponent;
    const baseForm = super.createBaseForm(closureDto, formBuilder, this.closureSubtypes, isTracked || closureEntryDto.hasInternalTracking);
    const closureFormGroup: FormGroup = formBuilder.group({
      ...baseForm.controls,
      entryId: [closureEntryDto.entryId],
      removedBeforeUsage: [closureEntryDto.removedBeforeUsage ?? null, [Validators.required]],
      removedByConsumer: [closureEntryDto.removedByConsumer ?? null, [Validators.required]],
      manifestationOverview: [super.createManifestationOverview(closureDto.multiMaterial?.layers ?? [])],
      manufacturingCountryName: [this.countriesService.getCountryNameByCode(closureDto.manufacturingCountry ?? '')],
      removedByConsumerValue: [super.booleanDictionaryKeyToValue(closureEntryDto.removedByConsumer ?? false)],
      hasInternalTracking: [closureEntryDto.hasInternalTracking],
      errorCount: [0],
      multiMaterial: closureDto.multiMaterial,
      isRigidComponent: [closureDto.isRigidComponent ?? null, [Validators.required]],
      printingCoverage: [
        closureDto.printingCoverage != null ? +(closureDto.printingCoverage * 100).toFixed(2) : null,
        [Validators.required, Validators.min(0), Validators.max(100)]
      ]
    });
    super.setErrorCountInPackagingComponent(closureFormGroup);
    return closureFormGroup;
  }

  getEmptyComponentEntry(): ClosureEntryDto {
    const closureEntry = new ClosureEntryDto();
    closureEntry.underlyingComponent = new ClosureDto();
    closureEntry.underlyingComponent.packagingComponentSubtypeId = this.closureSubtypes[0].id;
    return closureEntry;
  }

  getComponentEntriesFromForm(formValues: any): ClosureEntryDto[] {
    const closures: ClosureEntryDto[] = [];
    formValues.forEach((closureGroup: any) => {
      closures.push(this.getSingleComponentEntryFromForm(closureGroup, false));
    });
    return closures;
  }

  getSingleComponentEntryFromForm(closureFormValue: any, forInternalUse: boolean): ClosureEntryDto {
    const closureEntry = new ClosureEntryDto();
    this.setComponentEntryDataFromForm(closureEntry, closureFormValue);
    closureEntry.underlyingComponent = this.getSingleComponentFromForm(closureFormValue, forInternalUse);
    return closureEntry;
  }

  private setComponentEntryDataFromForm(closureEntry: ClosureEntryDto, closureFormValue: any) {
    closureEntry.entryId = closureFormValue?.entryId;
    closureEntry.hasInternalTracking = closureFormValue?.hasInternalTracking;
    closureEntry.removedBeforeUsage = closureFormValue?.removedBeforeUsage ?? null;
    closureEntry.removedByConsumer = closureFormValue?.removedByConsumer ?? null;
  }

  getSingleComponentFromForm(closureFormValue: any, forInternalUse: boolean, component?: ClosureDto): ClosureDto {
    const closureDto = component ?? new ClosureDto();
    closureDto.id = closureFormValue?.id;
    closureDto.versionName = closureFormValue?.newVersionName;
    closureDto.packagingComponentSubtypeId = closureFormValue?.packagingComponentSubtypeId;
    closureDto.comment = closureFormValue?.comment;
    closureDto.manufacturer = closureFormValue?.manufacturer;
    closureDto.manufacturingCountry = closureFormValue?.manufacturingCountry;
    closureDto.articleName = closureFormValue?.articleName;
    closureDto.articleNumber = closureFormValue?.articleNumber;
    closureDto.gtin = closureFormValue?.gtin;
    closureDto.isRigidComponent = closureFormValue?.isRigidComponent ?? null;
    closureDto.printingCoverage = forInternalUse ? closureFormValue?.printingCoverage :
      closureFormValue?.printingCoverage / 100;
    closureDto.length = closureFormValue?.length;
    closureDto.width = closureFormValue?.width;
    closureDto.height = closureFormValue?.height;
    closureDto.multiMaterial = closureFormValue?.multiMaterial;
    closureDto.containsNearInfraredBarrier = closureFormValue?.containsNearInfraredBarrier ?? null;
    if (closureDto.multiMaterial) {
      closureDto.multiMaterial.totalWeight = closureFormValue?.totalWeight;
      closureDto.multiMaterial.totalGrammage = closureFormValue?.totalGrammage;
    }
    return closureDto;
  }

  getDialogType() { return ClosureDialogComponent; }

  getComponentSubtypes(): ComponentSubtypeDto[] { return this.closureSubtypes; }

  setComponentSpecificDialogData(_: ComponentDialogData): void { }

  setComponentSpecificTableData(formGroup: FormGroup): void {
    formGroup.controls.removedByConsumerValue.patchValue(
      super.booleanDictionaryKeyToValue(formGroup.controls.removedByConsumer.value) ?? false);
    formGroup.controls.manifestationOverview.patchValue(
      super.createManifestationOverview((formGroup.controls.multiMaterial as FormGroup).value.layers ?? []));
  }
}
