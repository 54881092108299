import { CompositeMaterialExportProfileDto } from '../../../../../data-transfer/entities/material-entities/composite-material-export-profile-dto';
import { AuthService } from 'src/app/services/auth-service';
import { PackagingComponentExportProfileDto } from '../../../../../data-transfer/entities/component-entities/packaging-component-export-profile-dto';
import { DeleteItemDialogComponent } from '../../../../dialogs/delete-item-dialog/delete-item-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { SimpleAlertDialogComponent } from '../../../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { ImportExportApiService } from '../../../../../data-transfer/services/import-export-api-service';
import { ExportProfileDialogComponent } from '../export-profile-dialog/export-profile-dialog.component';
import { PackagingUnitExportProfileDto } from '../../../../../data-transfer/entities/packaging-unit-entities/packaging-unit-export-profile-dto';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DialogActions } from 'src/app/model/dictionary';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { PackagingSystemExportProfileDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-export-profile-dto';
import { ProfilesCollection } from 'src/app/data-transfer/resolvers/export-profile-resolver';
import { ExportProfilesDataSource } from '../export-profiles-data-source';

export interface ExportProfileDialogData {
  packagingSystemProfile: PackagingSystemExportProfileDto | null;
  packagingUnitProfile: PackagingUnitExportProfileDto | null;
  componentProfile: PackagingComponentExportProfileDto | null;
  materialProfile: CompositeMaterialExportProfileDto | null;
  namesUsed: Set<string>;
  isReadOnly: boolean;
}

@Component({
  selector: 'app-export-profiles',
  templateUrl: './export-profiles.component.html',
  styleUrls: ['./export-profiles.component.scss']
})
export class ExportProfilesComponent implements OnInit, OnDestroy {

  packagingSystemProfilesDataSource!: ExportProfilesDataSource;
  packagingUnitProfilesDataSource!: ExportProfilesDataSource;
  packagingComponentProfilesDataSource!: ExportProfilesDataSource;
  compositeMaterialProfilesDataSource!: ExportProfilesDataSource;
  displayedColumns = ['profileName', 'actions'];
  dialogActions = DialogActions;

  isAdmin = false;

  private routeDataSubscription?: Subscription;
  private setProfileSubscription?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private importExportApiService: ImportExportApiService,
    private translateService: TranslateService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.isAdmin = this.authService.isUserAdmin();
    this.routeDataSubscription = this.route.data.subscribe(data => {
      const profilesCollection: ProfilesCollection = data.profiles;
      const packagingSystemProfiles: PackagingSystemExportProfileDto[] = profilesCollection.packagingSystemProfiles;
      const packagingUnitProfiles: PackagingUnitExportProfileDto[] = profilesCollection.packagingUnitProfiles;
      const packagingComponentProfiles: PackagingComponentExportProfileDto[] = profilesCollection.componentProfiles;
      const compositeMaterialProfiles: CompositeMaterialExportProfileDto[] = profilesCollection.materialProfiles;

      this.packagingSystemProfilesDataSource = new ExportProfilesDataSource(packagingSystemProfiles);
      this.packagingUnitProfilesDataSource = new ExportProfilesDataSource(packagingUnitProfiles);
      this.packagingComponentProfilesDataSource = new ExportProfilesDataSource(packagingComponentProfiles);
      this.compositeMaterialProfilesDataSource = new ExportProfilesDataSource(compositeMaterialProfiles);
    });
  }

  async openPackagingSystemDialog(action: number, packagingSystemExportProfile?: PackagingSystemExportProfileDto) {
    const packagingSystemProfile = packagingSystemExportProfile ?? new PackagingSystemExportProfileDto(true);
    const namesUsed = new Set<string>(this.packagingSystemProfilesDataSource.data.filter(x =>
      x.profileName !== packagingSystemProfile.profileName).map(profile => profile.profileName) ?? []);
    const dialogData: ExportProfileDialogData = {
      packagingSystemProfile,
      packagingUnitProfile: null,
      componentProfile: null,
      materialProfile: null,
      namesUsed,
      isReadOnly: packagingSystemProfile.isDefaultProfile && !this.isAdmin
    };
    const dialogConfig = getDialogConfig(dialogData, '1265px');
    const dialogRef = this.dialog.open(ExportProfileDialogComponent, dialogConfig);
    const dialogResult = await dialogRef.afterClosed().toPromise();
    if (dialogResult.event === DialogActions.REJECT) { return; }
    const profileDto: PackagingSystemExportProfileDto = dialogResult.data.profile;
    this.setProfileSubscription = this.importExportApiService.setPackagingSystemExportProfile(profileDto, true).subscribe(profileId => {
      profileDto.id = profileId;
      if (action === DialogActions.ADD) {
        this.packagingSystemProfilesDataSource.data.push(profileDto);
      } else if (action === DialogActions.EDIT) {
        if (!packagingSystemExportProfile) { return; }
        const index = this.packagingSystemProfilesDataSource.data.indexOf(packagingSystemExportProfile);
        if (index != null) {
          this.packagingSystemProfilesDataSource.data[index] = profileDto;
        }
      }
      this.packagingSystemProfilesDataSource._updateChangeSubscription();
    }, _ => {
      this.showExportProfileSavingError();
    });
  }

  async openPackagingUnitDialog(action: number, packagingUnitExportProfile?: PackagingUnitExportProfileDto) {
    const packagingUnitProfile = packagingUnitExportProfile ?? new PackagingUnitExportProfileDto(true);
    const namesUsed = new Set<string>(this.packagingUnitProfilesDataSource.data.filter(x =>
      x.profileName !== packagingUnitProfile.profileName).map(profile => profile.profileName) ?? []);
    const dialogData: ExportProfileDialogData = {
      packagingSystemProfile: null,
      packagingUnitProfile,
      componentProfile: null,
      materialProfile: null,
      namesUsed,
      isReadOnly: packagingUnitProfile.isDefaultProfile && !this.isAdmin
    };
    const dialogConfig = getDialogConfig(dialogData, '1265px');
    const dialogRef = this.dialog.open(ExportProfileDialogComponent, dialogConfig);
    const dialogResult = await dialogRef.afterClosed().toPromise();
    if (dialogResult.event === DialogActions.REJECT) { return; }
    const profileDto: PackagingUnitExportProfileDto = dialogResult.data.profile;
    this.setProfileSubscription = this.importExportApiService.setPackagingUnitExportProfile(profileDto, true).subscribe(profileId => {
      profileDto.id = profileId;
      if (action === DialogActions.ADD) {
        this.packagingUnitProfilesDataSource.data.push(profileDto);
      } else if (action === DialogActions.EDIT) {
        if (!packagingUnitExportProfile) { return; }
        const index = this.packagingUnitProfilesDataSource.data.indexOf(packagingUnitExportProfile);
        if (index != null) {
          this.packagingUnitProfilesDataSource.data[index] = profileDto;
        }
      }
      this.packagingUnitProfilesDataSource._updateChangeSubscription();
    }, _ => {
      this.showExportProfileSavingError();
    });
  }

  async openPackagingComponentDialog(action: number, packagingComponentExportProfile?: PackagingComponentExportProfileDto) {
    const componentProfile = packagingComponentExportProfile ?? new PackagingComponentExportProfileDto(true);
    const namesUsed = new Set<string>(this.packagingComponentProfilesDataSource.data.filter(x =>
      x.profileName !== componentProfile.profileName).map(profile => profile.profileName) ?? []);
    const dialogData: ExportProfileDialogData = {
      packagingSystemProfile: null,
      packagingUnitProfile: null,
      componentProfile,
      materialProfile: null,
      namesUsed,
      isReadOnly: componentProfile.isDefaultProfile && !this.isAdmin
    };
    const dialogConfig = getDialogConfig(dialogData, '1200px');
    const dialogRef = this.dialog.open(ExportProfileDialogComponent, dialogConfig);
    const dialogResult = await dialogRef.afterClosed().toPromise();
    if (dialogResult.event === DialogActions.REJECT) { return; }
    const profileDto: PackagingComponentExportProfileDto = dialogResult.data.profile;
    this.setProfileSubscription = this.importExportApiService.setPackagingComponentExportProfile(profileDto, true).subscribe(profileId => {
      profileDto.id = profileId;
      if (action === DialogActions.ADD) {
        this.packagingComponentProfilesDataSource.data.push(profileDto);
      } else if (action === DialogActions.EDIT) {
        if (!packagingComponentExportProfile) { return; }
        const index = this.packagingComponentProfilesDataSource.data.indexOf(packagingComponentExportProfile);
        if (index != null) {
          this.packagingComponentProfilesDataSource.data[index] = profileDto;
        }
      }
      this.packagingComponentProfilesDataSource._updateChangeSubscription();
    }, _ => {
      this.showExportProfileSavingError();
    });
  }

  async openCompositeMaterialDialog(action: number, materialExportProfile?: CompositeMaterialExportProfileDto) {
    const materialProfile = materialExportProfile ?? new CompositeMaterialExportProfileDto(true);
    const namesUsed = new Set<string>(this.compositeMaterialProfilesDataSource.data.filter(x =>
      x.profileName !== materialProfile.profileName).map(profile => profile.profileName) ?? []);
    const dialogData: ExportProfileDialogData = {
      packagingSystemProfile: null,
      packagingUnitProfile: null,
      componentProfile: null,
      materialProfile,
      namesUsed,
      isReadOnly: materialProfile?.isDefaultProfile && !this.isAdmin
    };
    const dialogConfig = getDialogConfig(dialogData, '1200px');
    const dialogRef = this.dialog.open(ExportProfileDialogComponent, dialogConfig);
    const dialogResult = await dialogRef.afterClosed().toPromise();
    if (dialogResult.event === DialogActions.REJECT) { return; }
    const profileDto: CompositeMaterialExportProfileDto = dialogResult.data.profile;
    this.setProfileSubscription = this.importExportApiService.setCompositeMaterialExportProfile(profileDto, true).subscribe(profileId => {
      profileDto.id = profileId;
      if (action === DialogActions.ADD) {
        this.compositeMaterialProfilesDataSource.data.push(profileDto);
      } else if (action === DialogActions.EDIT) {
        if (!materialExportProfile) { return; }
        const index = this.compositeMaterialProfilesDataSource.data.indexOf(materialExportProfile);
        if (index != null) {
          this.compositeMaterialProfilesDataSource.data[index] = profileDto;
        }
      }
      this.compositeMaterialProfilesDataSource._updateChangeSubscription();
    }, _ => {
      this.showExportProfileSavingError();
    });
  }

  async deleteProfile(
    packagingSystemProfile: PackagingSystemExportProfileDto | null,
    packagingUnitProfile: PackagingUnitExportProfileDto | null,
    componentProfile: PackagingComponentExportProfileDto | null,
    materialProfile: CompositeMaterialExportProfileDto | null
  ) {
    const dialogData = {
      dialogHeader: this.translateService.instant('dataManagement.exportProfiles.messages.deleteProfileHeader'),
      dialogText: this.translateService.instant('dataManagement.exportProfiles.messages.deleteProfileText')
    };
    const dialogConfig = getDialogConfig(dialogData, '500px');
    const deleteDialogRef = this.dialog.open(DeleteItemDialogComponent, dialogConfig);
    const result = await deleteDialogRef.afterClosed().toPromise();
    if (result.event === DialogActions.REJECT) { return; }
    let deleteProfileResult;
    let index: number;
    if (packagingSystemProfile && packagingSystemProfile.id != null) {
      deleteProfileResult = await this.importExportApiService.deletePackagingSystemExportProfile(
        packagingSystemProfile.id).toPromise();
      index = this.packagingSystemProfilesDataSource.data.indexOf(packagingSystemProfile);
      if (index != null) {
        this.packagingSystemProfilesDataSource.data.splice(index, 1);
        this.packagingSystemProfilesDataSource._updateChangeSubscription();
      }
    }
    if (packagingUnitProfile && packagingUnitProfile.id != null) {
      deleteProfileResult = await this.importExportApiService.deletePackagingUnitExportProfile(
        packagingUnitProfile.id).toPromise();
      index = this.packagingUnitProfilesDataSource.data.indexOf(packagingUnitProfile);
      if (index != null) {
        this.packagingUnitProfilesDataSource.data.splice(index, 1);
        this.packagingUnitProfilesDataSource._updateChangeSubscription();
      }
    }
    if (componentProfile && componentProfile.id != null) {
      deleteProfileResult = await this.importExportApiService.deletePackagingComponentExportProfile(
        componentProfile.id).toPromise();
      index = this.packagingComponentProfilesDataSource.data.indexOf(componentProfile);
      if (index != null) {
        this.packagingComponentProfilesDataSource.data.splice(index, 1);
        this.packagingComponentProfilesDataSource._updateChangeSubscription();
      }
    }
    if (materialProfile && materialProfile.id != null) {
      deleteProfileResult = await this.importExportApiService.deleteCompositeMaterialExportProfile(
        materialProfile.id).toPromise();
      index = this.compositeMaterialProfilesDataSource.data.indexOf(materialProfile);
      if (index != null) {
        this.compositeMaterialProfilesDataSource.data.splice(index, 1);
        this.compositeMaterialProfilesDataSource._updateChangeSubscription();
      }
    }
  }

  private showExportProfileSavingError() {
    const dialogData = {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('dataManagement.exportProfiles.errors.backendError')],
      icon: 'error'
    };
    const dialogConfig = getDialogConfig(dialogData, '500px');
    this.dialog.open(SimpleAlertDialogComponent, dialogConfig);
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
    this.setProfileSubscription?.unsubscribe();
  }
}
