import { MaterialLayersReportDto } from '../material-entities/material-report-dto';
import { setValues } from '../packaging-unit-entities/packaging-unit-export-profile-dto';


export class ComponentReportDto {
  id?: number;
  profileName?: string;
  owningOrganizationId?: number;
  creationTimestamp?: string;
  isDeleted = false;
  isDefaultProfile = false;
  images = false;
  documents = false;
  multiMaterialTable: MaterialLayersReportDto;
  masterData: ComponentMasterData;

  constructor(defaultBooleanValue?: boolean) {
    this.multiMaterialTable = new MaterialLayersReportDto();
    this.masterData = new ComponentMasterData(defaultBooleanValue);
    if (defaultBooleanValue == null) { return; }
    this.images = defaultBooleanValue;
    this.documents = defaultBooleanValue;
  }
}

export class ComponentReportDependentDto {
  multiMaterialTable: MaterialLayersReportDto;
  masterData: ComponentMasterDataDependent;

  constructor(defaultBooleanValue?: boolean) {
    this.multiMaterialTable = new MaterialLayersReportDto();
    this.masterData = new ComponentMasterDataDependent(defaultBooleanValue);
  }
}

export class ComponentMasterDataDependent {
  componentType = false;
  articleNumber = false;
  articleName = false;
  gtin = false;
  printingCoverage = false;
  manufacturer = false;
  manufacturingCountry = false;
  dimensions = false;
  totalGrammage = false;
  containsNearInfraredBarrier = false;

  constructor(defaultBooleanValue?: boolean) {
    setValues(this, defaultBooleanValue);
  }
}

export class ComponentMasterData extends ComponentMasterDataDependent {
  packagingComponentId = false;
  version = false;
  distributionCountries = false;
  isRigidComponent = false;
  volume = false;

  constructor(defaultBooleanValue?: boolean) {
    super(defaultBooleanValue);
    setValues(this, defaultBooleanValue);
  }
}

class MainBodyMasterData extends ComponentMasterDataDependent {
  packagingVolume = false;

  constructor(defaultBooleanValue?: boolean) {
    super(defaultBooleanValue);
    setValues(this, defaultBooleanValue);
  }
}

class ClosureMasterData extends ComponentMasterDataDependent {
  isRigid = false;
  removedBeforeUsage = false;
  removedByConsumer = false;

  constructor(defaultBooleanValue?: boolean) {
    super(defaultBooleanValue);
    setValues(this, defaultBooleanValue);
  }
}

class DecorationMasterData extends ComponentMasterDataDependent {
  isAdhesiveUsed = false;
  removedBeforeUsage = false;
  removedByConsumer = false;

  constructor(defaultBooleanValue?: boolean) {
    super(defaultBooleanValue);
    setValues(this, defaultBooleanValue);
  }
}

class InlayMasterData extends ComponentMasterDataDependent {
  isRigid = false;
  isAdhesiveUsed = false;
  isPhysicallyAttached = false;

  constructor(defaultBooleanValue?: boolean) {
    super(defaultBooleanValue);
    setValues(this, defaultBooleanValue);
  }
}

class PackagingAidMasterData extends ComponentMasterDataDependent {
  isRigid = false;
  removedBeforeUsage = false;
  removedByConsumer = false;

  constructor(defaultBooleanValue?: boolean) {
    super(defaultBooleanValue);
    setValues(this, defaultBooleanValue);
  }
}


export class MainBodyReportDto extends ComponentReportDependentDto {
  masterData: MainBodyMasterData;

  constructor(defaultBooleanValue?: boolean) {
    super();
    this.masterData = new MainBodyMasterData(defaultBooleanValue);
  }
}

export class ClosureReportDto extends ComponentReportDependentDto {
  masterData: ClosureMasterData;

  constructor(defaultBooleanValue?: boolean) {
    super();
    this.masterData = new ClosureMasterData(defaultBooleanValue);
  }
}

export class DecorationReportDto extends ComponentReportDependentDto {
  masterData: DecorationMasterData;

  constructor(defaultBooleanValue?: boolean) {
    super();
    this.masterData = new DecorationMasterData(defaultBooleanValue);
  }
}

export class InlayReportDto extends ComponentReportDependentDto {
  masterData: InlayMasterData;

  constructor(defaultBooleanValue?: boolean) {
    super();
    this.masterData = new InlayMasterData(defaultBooleanValue);
  }
}

export class PackagingAidReportDto extends ComponentReportDependentDto {
  masterData: PackagingAidMasterData;

  constructor(defaultBooleanValue?: boolean) {
    super();
    this.masterData = new PackagingAidMasterData(defaultBooleanValue);
  }
}
