import { getDialogConfig } from 'src/app/util/dialog-util';
import { TagDto } from './../../../../data-transfer/entities/tag-dto';
import { ParentNavigationService } from './../../../../navigation/services/navigation-services/parent-navigation-service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatSort } from '@angular/material/sort';
import { OrganizationDto } from './../../../../data-transfer/entities/organization-dto';
import { Component, Inject, OnDestroy, ViewChild, LOCALE_ID, Input, EventEmitter, Output } from '@angular/core';
import { AnalysisMenuCaller, PackagingValidationMapping } from '../../analyses-menu/analyses-menu-parent/analyses-menu-parent.component';
import { SimpleAlertDialogComponent, SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { ExportDialogComponent } from 'src/app/components/directory-tree/dialogs/export-dialog/export-dialog.component';
import { DirectoryApiService } from 'src/app/data-transfer/services/directory-api-service';
import { ImportExportApiService } from 'src/app/data-transfer/services/import-export-api-service';
import { CreditsService } from 'src/app/services/credits-service';
import { PackagingComponentExportProfileDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-export-profile-dto';
import { CompositeMaterialExportProfileDto } from 'src/app/data-transfer/entities/material-entities/composite-material-export-profile-dto';
import { PackagingUnitExportProfileDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-export-profile-dto';
import { IMPORT_COST } from 'src/app/components/directory-tree/components/import/import-parent/import-parent.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { PackagingSystemExportProfileDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-export-profile-dto';
import { DialogActions } from 'src/app/model/dictionary';
import { DirectoryDto } from 'src/app/data-transfer/entities/directory-dto';
import { PackagingComponentDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-dto';
import { MultiMaterialCompositeDto } from 'src/app/data-transfer/entities/material-entities/multi-material-composite-dto';
import { PackagingSystemInfoDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-info-dto';
import { PackagingUnitInfoDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-info-dto';

@Component({
  selector: 'app-overview-table',
  templateUrl: './overview-table.component.html',
  styleUrls: ['./overview-table.component.scss']
})
export abstract class OverviewTableComponent implements OnDestroy {

  @ViewChild(MatSort) sort!: MatSort;

  @Input() allTags: TagDto[] = [];

  @Output() packagingPartsSelected = new EventEmitter();

  allItemsSelected = false;
  organizations: OrganizationDto[] = [];
  filteredColumns: string[] = [];
  callerPageId = AnalysisMenuCaller.Homepage;
  validationDataList: PackagingValidationMapping[] = [];

  displayTrackedColumn = false;

  protected moveBackendSubscription?: Subscription;
  protected copyBackendSubscription?: Subscription;
  protected organizationsSubscription?: Subscription;
  protected itemsInDirSubscription?: Subscription;
  protected deleteBackendSubscription?: Subscription;
  protected restoreSubscription?: Subscription;
  protected comparisonSubscription?: Subscription;
  protected dialogSubscription?: Subscription;
  protected permissionsSubscription?: Subscription;
  protected dataSourceSubscription?: Subscription;
  protected recSubscription?: Subscription;
  protected jsonSubscription?: Subscription;
  private exportBackendSubscription?: Subscription;

  constructor(
    protected dialog: MatDialog,
    protected directoryApiService: DirectoryApiService,
    protected importExportApiService: ImportExportApiService,
    protected navigationService: ParentNavigationService,
    protected translateService: TranslateService,
    protected spinner: NgxSpinnerService,
    protected creditsService: CreditsService,
    @Inject(LOCALE_ID) protected locale: string
  ) { }

  public abstract movePackagingPart(rootFolder: DirectoryDto, unreachableFolders: DirectoryDto[]): void;

  public abstract copyPackagingPart(rootFolder: DirectoryDto, unreachableFolders: DirectoryDto[]): void;

  public abstract deletePackagingPart(): void;

  public abstract restorePackagingPart(): void;
  
  public abstract exportPackagingPart(): void;
  
  public abstract comparePackagingPart(): void;
  
  public abstract editPackagingPartQuantity(): void;
  
  public abstract resetSelection(): void;
  
  public abstract getTagsSettingFunction(isEditing: boolean): (id: number, tagIds: number[]) => Observable<any>;

  protected setDataSourceFilter(dataSource: MatTableDataSource<any>) {
    dataSource.filterPredicate = (data, filter: any) => {
      let result = true;
      const keys = Object.keys(data);

      this.filteredColumns = Object.keys(filter.criterion);

      if (filter.criterion) {
        for (const key of keys) {
          const searchCondition = filter.criterion[key];

          if (searchCondition) {
            if (key === 'associatedTagIdentifiers') {
              const tags = this.getTagsFromIds(data[key]).map(tag => tag.name);
              if (filter.allCriteria[searchCondition](tags, filter.value[key]) === false) {
                result = false;
                break;
              }
            } else {
              if (filter.allCriteria[searchCondition](data[key], filter.value[key]) === false) {
                result = false;
                break;
              }
            }
          }
        }
      }
      return result;
    };
  }

  protected updateDisplayTrackedColumn(dataSource: MatTableDataSource<any>) {
    if (dataSource.paginator) {
      const startIndex = dataSource.paginator.pageIndex * dataSource.paginator.pageSize;
      this.displayTrackedColumn =
        dataSource.filteredData.slice(startIndex, startIndex + dataSource.paginator.pageSize)
          .filter(x => x.hasExternalTracking).length > 0;
    } else {
      this.displayTrackedColumn = dataSource.filteredData.filter(x => x.hasExternalTracking).length > 0;
    }
  }

  formatDateToString(timestamp: string) {
    if (timestamp) {
      return new Date(timestamp).toLocaleDateString(this.locale);
    } else {
      return '';
    }
  }

  getOrganizationName(organizationId: number): string {
    if (this.organizations.length > 0 && organizationId != null) {
      return this.organizations.find(x => x.id === organizationId)?.name ?? '';
    }
    return '';
  }

  setValidationData(e: PackagingValidationMapping) {
    this.validationDataList.push(e);
  }

  isFullyValidatedAllValid(packagingUnitId: number) {
    const allVersions = this.validationDataList.filter(x => x.id === packagingUnitId);
    const allInvalidVersions = allVersions.filter(x => x.isFullyValidatedAllValid === false || x.isNotFullyValidated === true);
    return allInvalidVersions.length === 0 && allVersions.length > 0;
  }

  isFullyValidatedNotAllValid(packagingUnitId: number) {
    const allVersions = this.validationDataList.filter(x => x.id === packagingUnitId);
    const allInvalidVersions = allVersions.filter(x => x.isFullyValidatedNotAllValid === true);
    const notAllValidated = allVersions.filter(x => x.isNotFullyValidated === true);
    return allInvalidVersions.length > 0 && notAllValidated.length === 0;
  }

  isNotFullyValidated(packagingUnitId: number) {
    const allVersions = this.validationDataList.filter(x => x.id === packagingUnitId);
    const notAllValidated = allVersions.filter(x => x.isNotFullyValidated === true);
    return notAllValidated.length > 0;
  }

  isTouched(packagingUnitId: number) {
    const allVersions = this.validationDataList.filter(x => x.id === packagingUnitId);
    return allVersions.find(x => x.isUntouched === false);
  }

  getExportSuccessDialogData(translateService: TranslateService): SimpleDialogData {
    return {
      title: translateService.instant('common.text.success'),
      messages: [translateService.instant('dataManagement.directory.dialog.export.exportSuccess')], icon: 'info'
    };
  }

  getExportErrorDialogData(translateService: TranslateService): SimpleDialogData {
    return {
      title: translateService.instant('common.text.error'),
      messages: [translateService.instant('dataManagement.directory.dialog.export.exportError')], icon: 'error'
    };
  }

  getDeleteNotAllowedData(translateService: TranslateService): SimpleDialogData {
    return {
      title: translateService.instant('common.text.error'),
      messages: [translateService.instant('warnings.deleteNotPermitted')], icon: 'error'
    };
  }

  getTagsFromIds(ids: number[]): TagDto[] {
    return this.allTags.filter(tag => ids.includes(tag.id ?? -1));
  }

  getAllTags(): string[] {
    return this.allTags.map(tag => tag.name);
  }

  getPossibleTags(dataSource: MatTableDataSource<any>): string[] {
    const tagIds = dataSource.data.map(data => data.associatedTagIdentifiers);
    const tagsIdsArray = tagIds.reduce((acc, val) => acc.concat(val), []);
    return this.allTags.filter(tag => tagsIdsArray.includes(tag.id ?? -1)).map(tag => tag.name);
  }

  protected async exportItems(
    dialogConfig: MatDialogConfig,
    exportProfilesFunction: () => Observable<
      PackagingSystemExportProfileDto[] |
      PackagingUnitExportProfileDto[] |
      PackagingComponentExportProfileDto[] |
      CompositeMaterialExportProfileDto[]>,
    exportFunction: (id: number, principalId: number, profileId?: number, subprofileId?: number,
      stayOnPageIfError?: boolean) => Observable<any>,
    isPackagingSystemExport = false
  ) {
    const securityPrincipals = await this.directoryApiService.getExternalSecurityPrincipals().toPromise();
    if (!securityPrincipals) { return; }
    const exportProfiles = await exportProfilesFunction().toPromise();
    dialogConfig.data.allPrincipals = securityPrincipals;
    dialogConfig.data.allProfiles = exportProfiles;
    if (isPackagingSystemExport) {
      const subExportProfiles = await this.importExportApiService.getPackagingUnitExportProfiles().toPromise();
      dialogConfig.data.allSubProfiles = subExportProfiles;
    }
    this.spinner.hide();
    const dialogRef = this.dialog.open(ExportDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      const requiredCredits = result.selectedPrincipalIds.length * result.selectedIds.length * IMPORT_COST;
      // TODO change to new credit type if import have a own credit type
      if (requiredCredits > this.creditsService.creditsCount.recyclabilityCredits) {
        this.navigationService.stopWhenCreditsInsufficient();
        return;
      }
      const exportResult: Observable<any>[] = [];
      const profileId = result.profileId;
      const subprofileId = result.subprofileId ?? null;
      for (const principalId of result.selectedPrincipalIds) {
        for (const packagingId of result.selectedIds) {
          exportResult.push(exportFunction(packagingId, principalId, profileId, subprofileId, true));
        }
      }
      this.exportBackendSubscription = forkJoin(exportResult).subscribe(_ => {
        this.creditsService.setCreditsCount();
        const dialogData = this.getExportSuccessDialogData(this.translateService);
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(dialogData, '500px'));
      }, _ => {
        const dialogData = this.getExportErrorDialogData(this.translateService);
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(dialogData, '500px'));
      });
    });
  }

  ngOnDestroy(): void {
    this.moveBackendSubscription?.unsubscribe();
    this.copyBackendSubscription?.unsubscribe();
    this.organizationsSubscription?.unsubscribe();
    this.itemsInDirSubscription?.unsubscribe();
    this.deleteBackendSubscription?.unsubscribe();
    this.restoreSubscription?.unsubscribe();
    this.comparisonSubscription?.unsubscribe();
    this.dialogSubscription?.unsubscribe();
    this.permissionsSubscription?.unsubscribe();
    this.dataSourceSubscription?.unsubscribe();
    this.recSubscription?.unsubscribe();
    this.jsonSubscription?.unsubscribe();
    this.exportBackendSubscription?.unsubscribe();
  }
}
