
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ParentApiService } from './parent-api-service';
import { Observable } from 'rxjs';
import { EvaluationGenerationDto } from '../entities/evaluation-entities/evaluation-generation-dto';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { RecyclabilityResultDto } from '../entities/evaluation-entities/recyclability-result-dto';
import { LifeCycleResultDto } from '../entities/evaluation-entities/life-cycle-result-dto';
import { PackagingUnitRecyclabilityResultDto } from '../entities/evaluation-entities/packaging-part-results/packaging-unit-recyclability-result-dto';
import { PackagingSystemRecyclabilityResultDto } from '../entities/evaluation-entities/packaging-part-results/packaging-system-recyclability-result-dto';
import { PackagingSystemLifeCycleResultDto } from '../entities/evaluation-entities/packaging-part-results/packaging-system-life-cycle-result-dto';
import { PackagingUnitLifeCycleResultDto } from '../entities/evaluation-entities/packaging-part-results/packaging-unit-life-cycle-result-dto';
import { RecyclingStreamTranslationsDto } from '../entities/evaluation-entities/recycling-stream-dto';
import { ProblemInformationDto } from '../entities/evaluation-entities/problem-information-dto';
import { ExpenseResultDto } from '../entities/evaluation-entities/expense-result-dto';

const UNITS_CONTROLLER = 'packaging-units';
const SYSTEMS_CONTROLLER = 'packaging-systems';

@Injectable({ providedIn: 'root' })
export class AnalysisApiService extends ParentApiService {

  constructor(private http: HttpClient) { super(); }

  private getControllerName(packagingPart: number): string {
    if (packagingPart !== PackagingPart.Unit && packagingPart !== PackagingPart.System) {
      throw new Error("AnalysisApiService: evaluations not implemented for current packaging level");
    }
    return packagingPart === PackagingPart.Unit ? UNITS_CONTROLLER : SYSTEMS_CONTROLLER;
  }

  public getRecyclabilityEvaluationResult(dto: EvaluationGenerationDto, packagingPart: number): Observable<RecyclabilityResultDto[]> {
    const controllerName = this.getControllerName(packagingPart);
    const url = `${environment.webApiAddress}/api/${controllerName}/evaluate-recyclability`;
    return this.http.post<RecyclabilityResultDto[]>(url, dto);
  }

  public getLifeCycleEvaluationResult(dto: EvaluationGenerationDto, packagingPart: number): Observable<LifeCycleResultDto[]> {
    const controllerName = this.getControllerName(packagingPart);
    const url = `${environment.webApiAddress}/api/${controllerName}/evaluate-lifecycle`;
    return this.http.post<LifeCycleResultDto[]>(url, dto);
  }

  public getPlasticTaxCalculationResult(dto: EvaluationGenerationDto, packagingPart: number): Observable<ExpenseResultDto[]> {
    const controllerName = this.getControllerName(packagingPart);
    const url = `${environment.webApiAddress}/api/${controllerName}/calculate-plastic-tax`;
    return this.http.post<ExpenseResultDto[]>(url, dto);
  }

  public getLicenseFeeCalculationResult(dto: EvaluationGenerationDto, packagingPart: number): Observable<ExpenseResultDto[]> {
    const controllerName = this.getControllerName(packagingPart);
    const url = `${environment.webApiAddress}/api/${controllerName}/calculate-license-fee`;
    return this.http.post<ExpenseResultDto[]>(url, dto);
  }

  public getPackagingUnitRecyclabilityHistoryResults(id: number, versionId: number): Observable<PackagingUnitRecyclabilityResultDto[][]> {
    const url = `${environment.webApiAddress}/api/packaging-units/evaluation/history/recyclability?id=${id}&version=${versionId}`;
    return this.http.get<PackagingUnitRecyclabilityResultDto[][]>(url);
  }

  public getPackagingUnitExpenseHistoryResults(id: number, versionId: number): Observable<ExpenseResultDto[][]> {
    const url = `${environment.webApiAddress}/api/packaging-units/evaluation/history/expense?id=${id}&version=${versionId}`;
    return this.http.get<ExpenseResultDto[][]>(url);
  }

  public getPackagingSystemRecyclabilityHistoryResults(
    id: number, versionId: number
  ): Observable<PackagingSystemRecyclabilityResultDto[][]> {
    const url = `${environment.webApiAddress}/api/packaging-systems/evaluation/history/recyclability?id=${id}&version=${versionId}`;
    return this.http.get<PackagingSystemRecyclabilityResultDto[][]>(url);
  }

  public getPackagingUnitLifeCycleHistoryResults(id: number, versionId: number): Observable<PackagingUnitLifeCycleResultDto[][]> {
    const url = `${environment.webApiAddress}/api/packaging-units/evaluation/history/lifecycle?id=${id}&version=${versionId}`;
    return this.http.get<PackagingUnitLifeCycleResultDto[][]>(url);
  }

  public getPackagingSystemLifeCycleHistoryResults(id: number, versionId: number): Observable<PackagingSystemLifeCycleResultDto[][]> {
    const url = `${environment.webApiAddress}/api/packaging-systems/evaluation/history/lifecycle?id=${id}&version=${versionId}`;
    return this.http.get<PackagingSystemLifeCycleResultDto[][]>(url);
  }

  public getPackagingSystemExpenseHistoryResults(id: number, versionId: number): Observable<ExpenseResultDto[][]> {
    const url = `${environment.webApiAddress}/api/packaging-systems/evaluation/history/lifecycle?id=${id}&version=${versionId}`;
    return this.http.get<ExpenseResultDto[][]>(url);
  }

  public generateLcaExcel(id: number, versionId: number, analysisVersionId: string, packagingPart: number) {
    const controllerName = this.getControllerName(packagingPart);
    let url = `${environment.webApiAddress}/api/${controllerName}/evaluate-lifecycle/excel/${id}?version=${versionId}`;
    if (analysisVersionId != null && analysisVersionId !== '') {
      url = url.concat(`&evaluationId=${analysisVersionId}`);
    }
    return this.http.get(url, { responseType: 'blob' });
  }

  public getRecyclabilityCreditsRequirement(id: number, packagingPart: number, versionId?: number) {
    const controllerName = this.getControllerName(packagingPart);
    let url = `${environment.webApiAddress}/api/${controllerName}/recyclability-evaluation-costs?id=${id}`;
    if (versionId != null) {
      url = url.concat(`&version=${versionId}`);
    }
    return this.http.get<number>(url);
  }

  public getLifeCycleCreditsRequirement(id: number, packagingPart: number, versionId?: number) {
    const controllerName = this.getControllerName(packagingPart);
    let url = `${environment.webApiAddress}/api/${controllerName}/lifecycle-evaluation-costs?id=${id}`;
    if (versionId != null) {
      url = url.concat(`&version=${versionId}`);
    }
    return this.http.get<number>(url);
  }

  public getExpenseCreditsRequirement(id: number, packagingPart: number, versionId?: number) {
    const controllerName = this.getControllerName(packagingPart);
    let url = `${environment.webApiAddress}/api/${controllerName}/expense-calculation-costs?id=${id}`;
    if (versionId != null) {
      url = url.concat(`&version=${versionId}`);
    }
    return this.http.get<number>(url);
  }

  public isLifeCycleAnalysisPossible(
    id: number, ignoreDistributionCountries: boolean, packagingPart: number, version?: number
  ): Observable<boolean> {
    const controllerName = this.getControllerName(packagingPart);
    let params: HttpParams = new HttpParams();
    params = params.set('id', id);
    params = params.set('ignoreDistributionCountries', ignoreDistributionCountries);
    if (version != null) {
      params = params.set('version', version);
    }
    const url = `${environment.webApiAddress}/api/${controllerName}/is-lifecycle-analysis-possible`;
    return this.http.get<boolean>(url, { params });
  }

  public isRecyclabilityAnalysisPossible(
    id: number, ignoreDistributionCountries: boolean, packagingPart: number, version?: number
  ): Observable<boolean> {
    const controllerName = this.getControllerName(packagingPart);
    let params: HttpParams = new HttpParams();
    params = params.set('id', id);
    params = params.set('ignoreDistributionCountries', ignoreDistributionCountries);
    if (version != null) {
      params = params.set('version', version);
    }
    const url = `${environment.webApiAddress}/api/${controllerName}/is-recyclability-analysis-possible`;
    return this.http.get<boolean>(url, { params });
  }

  public isExpenseAnalysisPossible(
    id: number, ignoreDistributionCountries: boolean, packagingPart: number, version?: number
  ): Observable<boolean> {
    const controllerName = this.getControllerName(packagingPart);
    let params: HttpParams = new HttpParams();
    params = params.set('id', id);
    params = params.set('ignoreDistributionCountries', ignoreDistributionCountries);
    if (version != null) {
      params = params.set('version', version);
    }
    const url = `${environment.webApiAddress}/api/${controllerName}/is-expense-calculation-possible`;
    return this.http.get<boolean>(url, { params });
  } 

  public getExpenseAnalysisProblemInformation(
    id: number, packagingPart: number, version?: number
  ): Observable<ProblemInformationDto[]> {
    const controllerName = this.getControllerName(packagingPart);
    let params: HttpParams = new HttpParams();
    params = params.set('id', id);
    if (version != null) {
      params = params.set('version', version);
    }
    const url = `${environment.webApiAddress}/api/${controllerName}/expense-calculation-problem-information`;
    return this.http.get<ProblemInformationDto[]>(url, { params });
  }

  public isLifeCycleAnalysisPossibleImport(
    candidateId: number, packagingPart: number, ignoreDistributionCountries = true
  ): Observable<boolean> {
    const controllerName = this.getControllerName(packagingPart);
    let params: HttpParams = new HttpParams();
    params = params.set('importCandidateId', candidateId);
    params = params.set('ignoreDistributionCountries', ignoreDistributionCountries);
    const url = `${environment.webApiAddress}/api/${controllerName}/is-lifecycle-analysis-possible/by-import-candidate`;
    return this.http.get<boolean>(url, { params });
  }

  public isRecyclabilityAnalysisPossibleImport(
    candidateId: number, packagingPart: number, ignoreDistributionCountries = true
  ): Observable<boolean> {
    const controllerName = this.getControllerName(packagingPart);
    let params: HttpParams = new HttpParams();
    params = params.set('importCandidateId', candidateId);
    params = params.set('ignoreDistributionCountries', ignoreDistributionCountries);
    const url = `${environment.webApiAddress}/api/${controllerName}/is-recyclability-analysis-possible/by-import-candidate`;
    return this.http.get<boolean>(url, { params });
  } 

  public getLifeCycleAnalysisProblemInformation(
    id: number, packagingPart: number, version?: number
  ): Observable<ProblemInformationDto[]> {
    const controllerName = this.getControllerName(packagingPart);
    let params: HttpParams = new HttpParams();
    params = params.set('id', id);
    if (version != null) {
      params = params.set('version', version);
    }
    const url = `${environment.webApiAddress}/api/${controllerName}/life-cycle-analysis-problem-information`;
    return this.http.get<ProblemInformationDto[]>(url, { params });
  }

  public getRecyclabilityAnalysisProblemInformation(
    id: number, packagingPart: number, version?: number
  ): Observable<ProblemInformationDto[]> {
    const controllerName = this.getControllerName(packagingPart);
    let params: HttpParams = new HttpParams();
    params = params.set('id', id);
    if (version != null) {
      params = params.set('version', version);
    }
    const url = `${environment.webApiAddress}/api/${controllerName}/recyclability-analysis-problem-information`;
    return this.http.get<ProblemInformationDto[]>(url, { params });
  }

  public getLifeCycleAnalysisProblemInformationImport(
    candidateId: number, packagingPart: number
  ): Observable<ProblemInformationDto[]> {
    const controllerName = this.getControllerName(packagingPart);
    let params: HttpParams = new HttpParams();
    params = params.set('importCandidateId', candidateId);
    const url = `${environment.webApiAddress}/api/${controllerName}/life-cycle-analysis-problem-information/by-import-candidate`;
    return this.http.get<ProblemInformationDto[]>(url, { params });
  }

  public getRecyclabilityAnalysisProblemInformationImport(
    candidateId: number, packagingPart: number
  ): Observable<ProblemInformationDto[]> {
    const controllerName = this.getControllerName(packagingPart);
    let params: HttpParams = new HttpParams();
    params = params.set('importCandidateId', candidateId);
    const url = `${environment.webApiAddress}/api/${controllerName}/recyclability-analysis-problem-information/by-import-candidate`;
    return this.http.get<ProblemInformationDto[]>(url, { params });
  }

  public setAnalysisViewedTimestamp(analysisId: string, stayOnPageIfError?: boolean) {
    const url = `${environment.webApiAddress}/api/validation/update-last-access-timestamp?analysisId=${analysisId}`;
    return this.http.post<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public getRecyclingStreams(): Observable<RecyclingStreamTranslationsDto[]> {
    const url = `${environment.webApiAddress}/api/recycling-streams/translations`;
    return this.http.get<RecyclingStreamTranslationsDto[]>(url);
  }
}