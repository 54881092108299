import { CreateUpdateHandler } from './../../../services/packaging-services/create-update-handler';
import { AdditionalPackagingComponentsParentDialogComponent } from './../additional-packaging-components-parent-dialog/additional-packaging-components-parent-dialog.component';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PackagingComponentTypesEnum } from 'src/app/model/packaging-component-types-enum';
import { GtinCheckService } from 'src/app/services/component-services/gtin-check-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GENERIC_ANALYZABLE_PACKAGING_TYPE_IDS, GENERIC_RIGID_PACKAGING_TYPE_ID } from 'src/app/navigation/services/packaging-unit-type-service';

@Component({
  selector: 'app-main-body-dialog',
  templateUrl: './main-body-dialog.component.html',
  styleUrls: ['./main-body-dialog.component.scss']
})
export class MainBodyDialogComponent extends AdditionalPackagingComponentsParentDialogComponent implements OnInit {

  addEditMainBodyForm: FormGroup;

  constructor(
    protected dialogRef: MatDialogRef<MainBodyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    protected gtinCheckService: GtinCheckService,
    private handler: CreateUpdateHandler
  ) {
    super(dialogRef, gtinCheckService);
    this.callerId = PackagingComponentTypesEnum.MainBody;
    super.setCommonComponentData(this.data);
    this.addEditMainBodyForm = this.createFormCopy(data.baseForm);
    super.subscribeToValueChanges(this.addEditMainBodyForm);
    this.checkSubtypeSetRigidField(this.addEditMainBodyForm.value.packagingComponentSubtypeId);
    this.addEditMainBodyForm.controls.packagingComponentSubtypeId.valueChanges.subscribe(newType => {
      this.checkSubtypeSetRigidField(newType);
    });
  }

  ngOnInit(): void {
    if (!this.canEditForm) {
      this.addEditMainBodyForm.disable();
    }
    this.typeSubscription = this.addEditMainBodyForm.controls.packagingComponentSubtypeId.valueChanges.subscribe(newTypeId => {
      if (newTypeId == null) { return; }
      this.packagingUnitTypeId = newTypeId;
      this.allMaterialFunctions = this.handler.componentHandler.getMaterialFunctions(
        this.packagingUnitTypeId, PackagingComponentTypesEnum.MainBody);
    });
    this.selectedManufacturingCountry = this.addEditMainBodyForm.controls.manufacturingCountry.value ?? '';
  }

  private checkSubtypeSetRigidField(subtypeId: number) {
    if (GENERIC_ANALYZABLE_PACKAGING_TYPE_IDS.includes(subtypeId)) {
      this.addEditMainBodyForm.controls.isRigidComponent.patchValue(subtypeId === GENERIC_RIGID_PACKAGING_TYPE_ID ? true : false);
      this.addEditMainBodyForm.controls.isRigidComponent.disable();
    } else {
      this.addEditMainBodyForm.controls.isRigidComponent.enable();
    }
  }

  async doAction() {
    // this.data.baseForm = this.addEditMainBodyForm;
    super.doAction(this.addEditMainBodyForm);
  }
}
