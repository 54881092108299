import { AdditionalPackagingComponentsParentDialogComponent } from './../additional-packaging-components-parent-dialog/additional-packaging-components-parent-dialog.component';
import { Component, Inject, Optional, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PackagingComponentTypesEnum } from 'src/app/model/packaging-component-types-enum';
import { GtinCheckService } from 'src/app/services/component-services/gtin-check-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-packaging-aid-dialog',
  templateUrl: './packaging-aid-dialog.component.html',
  styleUrls: ['./packaging-aid-dialog.component.scss']
})
export class PackagingAidDialogComponent extends AdditionalPackagingComponentsParentDialogComponent implements OnInit {

  addEditPackagingAidForm!: FormGroup;

  constructor(
    protected dialogRef: MatDialogRef<PackagingAidDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: any,
    protected gtinCheckService: GtinCheckService
  ) {
    super(dialogRef, gtinCheckService);
    this.callerId = PackagingComponentTypesEnum.PackagingAid;
    if (this.data) {
      super.setCommonComponentData(this.data);
      this.addEditPackagingAidForm = this.createFormCopy(data.baseForm);
      super.subscribeToValueChanges(this.addEditPackagingAidForm);
    }
  }

  ngOnInit(): void {
    if (!this.canEditForm) {
      this.addEditPackagingAidForm.disable();
    }
    this.selectedManufacturingCountry = this.addEditPackagingAidForm.controls.manufacturingCountry.value ?? '';
  }

  async doAction() { super.doAction(this.addEditPackagingAidForm); }
}
