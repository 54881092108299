import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import {ReportApiService} from 'src/app/data-transfer/services/report-api-service';
import {AuthService} from 'src/app/services/auth-service';
@Component({
  selector: 'app-downloads-menu',
  templateUrl: './downloads-menu.component.html',
  styleUrls: ['./downloads-menu.component.scss']
})
export class DownloadsMenuComponent {

  documents!: { label: string, path: string }[];
  isAdmin = false;

  constructor(
    private translateService: TranslateService,
    private reportservice: ReportApiService,
    private authService: AuthService
  ) {
    this.setDocuments();
    this.isAdmin = this.authService.isUserAdmin();
  }

  private setDocuments() {
    const documentsFolder = 'https://cockpit.packaging-cockpit.com/downloads/documents/';
    const format = '.pdf';
    const isEnglish = localStorage.getItem('language') === 'en';
    this.documents = [
      {
        path: isEnglish ? `${documentsFolder}UserManual${format}` :
          `${documentsFolder}UserManual_DE${format}`,
        label: this.translateService.instant('downloads.file.manual')
      },
      {
        path: isEnglish ? `${documentsFolder}FH-Campus-Wien_Circular-Packaging-Design-Guideline_V04_EN${format}` :
          `${documentsFolder}FH-Campus-Wien_Circular-Packaging-Design-Guideline_V04_DE${format}`,
        label: this.translateService.instant('downloads.file.guidelines')
      },
      {
        path: isEnglish ? `${documentsFolder}Recyclability-Assessment-CA${format}` :
          `${documentsFolder}Bewertung_RF${format}`,
        label: this.translateService.instant('downloads.file.methods')
      }
    ];
  }

  clickExternalHtml() {
    const link = document.createElement('a');
    link.href = 'api-documentation/index.html';
    link.target = '_blank';
    link.click();
  }


  async downloadUsageReport() {
    const observable = this.reportservice.generateUsageReport();
    const reportPdf = await observable.toPromise();
    const pdfUrl = window.URL.createObjectURL(reportPdf);
    window.open(pdfUrl, '_blank');
  }
}
