import { getDialogConfig } from 'src/app/util/dialog-util';
import { VersionDto } from './../../../../../data-transfer/entities/version-dto';
import { ComponentHandler } from 'src/app/services/component-services/component-handler';
import { SelectLocationDialogComponent } from './../../../../directory-tree/dialogs/select-location-dialog/select-location-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from './../../../../../services/auth-service';
import { PermissionTypeDto } from './../../../../../data-transfer/entities/permission-type-dto';
import { PdfUploadComponent } from './../../../../shared-components/upload/pdf-upload/pdf-upload.component';
import { ImageUploadComponent } from './../../../../shared-components/upload/image-upload/image-upload.component';
import { DialogActions, DictionaryHandler } from './../../../../../model/dictionary';
import { PackagingComponentDto } from '../../../../../data-transfer/entities/component-entities/packaging-component-dto';
import { ComponentTypeService } from 'src/app/navigation/services/component-type-service';
import { ComponentApiService } from '../../../../../data-transfer/services/component-api-service';
import { Subscription, forkJoin } from 'rxjs';
import { SimpleAlertDialogComponent, SimpleDialogData } from './../../../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { FormGroup } from '@angular/forms';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ComponentNavigationService } from 'src/app/navigation/services/navigation-services/component-navigation-service';
import { FileApiService } from 'src/app/data-transfer/services/file-api-service';
import { GtinCheckService } from 'src/app/services/component-services/gtin-check-service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { PackagingComponentTypesEnum } from 'src/app/model/packaging-component-types-enum';
import { VersionComponentDto } from 'src/app/data-transfer/entities/version-component-dto';
import { ComponentTypeDto } from 'src/app/data-transfer/entities/component-type-dto';
import { ComponentSubtypeDto } from 'src/app/data-transfer/entities/component-subtype-dto';
import { interval } from 'rxjs';
import { mergeMap, startWith } from 'rxjs/operators';
import { ChangedPackagingPartDisplayInfo, FOUR_MINUTES, TEN_MINUTES } from 'src/app/components/shared-components/parent-components/create-update-packaging-part/create-update-packaging-part.component';
import { OptionalVersionDialogComponent } from 'src/app/components/dialogs/optional-version-dialog/optional-version-dialog.component';
import { SimpleConfirmDialogComponent } from 'src/app/components/dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { MultiMaterialCompositeDto } from 'src/app/data-transfer/entities/material-entities/multi-material-composite-dto';
import { PackagingPart } from 'src/app/model/packaging-part-enum';

@Component({
  selector: 'app-component-create-update-parent',
  templateUrl: './component-create-update-parent.component.html',
  styleUrls: ['./component-create-update-parent.component.scss']
})
export class ComponentCreateUpdateParentComponent implements OnDestroy {

  @ViewChild(ImageUploadComponent) private imageUploadComponent!: ImageUploadComponent;
  @ViewChild(PdfUploadComponent) private pdfUploadComponent!: PdfUploadComponent;

  form!: FormGroup;
  isUserValidator = false;
  isUserAdmin = false;
  isHistory = false;
  isPreview = false;
  isFormLocked = false;
  isTracked = false;
  spinnerActive = false;
  filesEdited = false;
  canEditForm = true;
  isEditPermitted = true;
  permissions!: PermissionTypeDto;
  materialEdited = false;
  changedMaterialsInfo: ChangedPackagingPartDisplayInfo[] = [];

  // Type as seen in the extended list: closure, ..., banderole, label etc.
  componentType!: ComponentTypeDto;
  callerId = -1;

  componentSubtypes: ComponentSubtypeDto[] = [];
  historyVersions: VersionComponentDto[] = [];

  currentDirectoryId?: number;
  isDarkTheme = false;
  imageSource = '';

  private putComponentSubscription?: Subscription;
  private getComponentSubscription?: Subscription;
  private releaseSubscription?: Subscription;
  private lockSubscription?: Subscription;
  private saveFilesSubscription?: Subscription;
  private dialogSubscription?: Subscription;
  private gtinChangesSubscription?: Subscription;
  private historySubscription?: Subscription;
  private renamingSubscription?: Subscription;
  private materialChangeSubscription?: Subscription;
  protected routeParamsSubscription?: Subscription;
  protected routeDataSubscription?: Subscription;
  protected themeSubscription?: Subscription;
  protected valueChangeSubscription?: Subscription;
  protected heartBeatSubscription?: Subscription;

  constructor(
    protected componentHandler: ComponentHandler,
    protected dialog: MatDialog,
    protected spinner: NgxSpinnerService,
    protected componentApiService: ComponentApiService,
    protected navigationService: ComponentNavigationService,
    protected componentTypeService: ComponentTypeService,
    protected dictionaryHandler: DictionaryHandler,
    protected fileApiService: FileApiService,
    protected authService: AuthService,
    protected gtinCheckService: GtinCheckService
  ) {
    this.isUserAdmin = this.authService.isUserAdmin();
    this.isUserValidator = this.authService.isUserValidator();
  }

  protected initComponent(component: PackagingComponentDto, route: ActivatedRoute) {
    this.routeParamsSubscription = route.params.subscribe(params => {
      if (params.candidateId) { this.isPreview = true; }
    });
    this.isEditPermitted = this.permissions?.write ?? true;
    this.isHistory = component.id != null && !component.isCurrentVersion;
    this.isTracked = component.hasExternalTracking ?? false;
  }

  protected initGtinCheck() {
    this.gtinChangesSubscription = this.form.controls.gtin.valueChanges.subscribe(async val => {
      this.form.controls.gtin.setValue(val?.trim(), { emitEvent: false });
      await this.gtinCheckService.checkGtinValidity(this.form);
      this.form.controls.gtin.markAsTouched();
    });
  }

  protected disableFormIfRequired() {
    const editable = this.permissions?.write ?? true;
    if (this.isHistory || this.isUserValidator || this.isUserAdmin || this.isPreview || !editable || this.isFormLocked) {
      this.canEditForm = false;
      this.form.disable();
    }
  }

  protected async releaseComponent(packagingComponent: PackagingComponentDto) {
    if (!this.isFormLocked && packagingComponent.id != null && !this.isPreview && await this.authService.isSignedIn()) {
      this.releaseSubscription = await this.componentApiService.releasePackagingComponent(packagingComponent.id, true).toPromise();
    }
  }

  private getComponentTypeNameEnglish() {
    return this.componentTypeService.getComponentTypeNameById(this.componentType?.id);
  }

  onHistoryVersionChange(version: number) {
    const selectedVersion = this.historyVersions.find(x => x.versionNumber === version);
    if (!selectedVersion || selectedVersion.id == null) { return; }
    if (selectedVersion.isCurrentVersion) {
      if (selectedVersion.packagingComponentCategoryId == PackagingComponentTypesEnum.Decoration) {
        this.navigationService.navigateToDecoration(this.getComponentTypeNameEnglish(),
          selectedVersion.id);
      } else {
        this.navigationService.navigateToComponent(this.getComponentTypeNameEnglish(),
          selectedVersion.id);
      }
    } else {
      if (selectedVersion.versionNumber == null) { return; }
      if (selectedVersion.packagingComponentCategoryId == PackagingComponentTypesEnum.Decoration) {
        this.navigationService.navigateToHistoryDecoration(this.getComponentTypeNameEnglish(),
          selectedVersion.id, selectedVersion.versionNumber);
      } else {
        this.navigationService.navigateToHistoryComponent(this.getComponentTypeNameEnglish(),
          selectedVersion.id, selectedVersion.versionNumber);
      }
    }
  }

  loadHistoryVersions(componentId: number | null | undefined) {
    if (componentId == null) { return; }
    if (this.historyVersions.length <= 1) {
      this.spinnerActive = true;
      this.historySubscription = this.componentApiService.getComponentHistoryVersions(componentId)
        .subscribe(versions => {
          this.historyVersions = versions.sort((a, b) =>
            b.versionNumber != null && a.versionNumber != null ? b.versionNumber - a.versionNumber : 0);
          this.spinnerActive = false;
        });
    }
  }

  protected async onSubmit(packagingComponent: PackagingComponentDto) {
    this.form.updateValueAndValidity();
    if (this.form.invalid) {
      const dialogData = this.componentHandler.getInvalidDialogData();
      const alertDialogConfig = getDialogConfig(dialogData);
      this.dialog.open(SimpleAlertDialogComponent, alertDialogConfig);
    } else {
      if (this.currentDirectoryId == null) {
        this.proceedToDirectorySelection(packagingComponent);
      } else {
        this.setDataAndPutComponent(packagingComponent);
      }
    }
  }

  protected changeVersionName(historyVersion: VersionDto, newName: string, component: PackagingComponentDto) {
    if (historyVersion.id == null || historyVersion.versionNumber == null) { return; }
    this.renamingSubscription = this.componentApiService.
      putRenamePackagingComponent(historyVersion.id, historyVersion.versionNumber, newName, true)
      .subscribe(_ => {
        historyVersion.versionName = newName;
        if (historyVersion.versionNumber === component.version) {
          component.versionName = newName;
        }
      });
  }

  private proceedToDirectorySelection(component: PackagingComponentDto) {
    const rootFolder = this.componentHandler.rootDirectory;
    if (!rootFolder) {
      console.log('No directories available');
      return;
    }
    if (!rootFolder.childDirectories || rootFolder.childDirectories.length < 1) {
      component.directoryId = rootFolder.id;
      this.setDataAndPutComponent(component);
      return;
    }
    const dialogConfig = getDialogConfig({ rootFolder, unreachableFolders: [] }, '500px');
    const dialogRef = this.dialog.open(SelectLocationDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      component.directoryId = result.data.id;
      this.setDataAndPutComponent(component);
    }, error => {
      console.log(error);
    });
  }

  private setDataAndPutComponent(packagingComponent: PackagingComponentDto) {
    packagingComponent.distributionCountries = this.form.controls.distributionCountries.value ?? [];

    const newImagesFormData = this.imageUploadComponent.getNewImagesFormData();
    const newPdfsFormData = this.pdfUploadComponent.getNewPdfsFormData();

    const imageObservables = this.fileApiService.putImages(newImagesFormData);
    const pdfObservables = this.fileApiService.putDocuments(newPdfsFormData);

    this.saveFilesSubscription = forkJoin([imageObservables, pdfObservables]).subscribe(async allIdsList => {
      const imageIdsList = allIdsList[0];
      const pdfIdsList = allIdsList[1];

      const packagingImageIds = packagingComponent.images.map(x => x.imageId);
      for (const imageId of imageIdsList) {
        if (!packagingImageIds.includes(imageId)) {
          packagingComponent.images.push({
            imageId, index: packagingComponent.images.length, isMainImage: false, name: ''
          });
        }
      }

      this.imageUploadComponent.recalculateImageIndices();
      this.imageUploadComponent.setMainImage();
      this.imageUploadComponent.setImageNames();

      const newPackagingDocuments = this.pdfUploadComponent.pdfs.filter(x => x.documentId == null);
      for (let index = 0; index < pdfIdsList.length; index++) {
        newPackagingDocuments[index].documentId = pdfIdsList[index];
      }

      let shouldCreateNewVersion = true;
      if (packagingComponent.id != null && (!this.form.pristine || this.materialEdited)) {
        shouldCreateNewVersion = await this.shouldCreateNewVersion();
        if (shouldCreateNewVersion == null) { return; }
      }
      this.putComponent(packagingComponent, shouldCreateNewVersion);
    });
  }

  private async shouldCreateNewVersion() {
    const dialogData: SimpleDialogData = this.componentHandler.getCreateNewVersionDialogData();
    const dialogRef = this.dialog.open(OptionalVersionDialogComponent, getDialogConfig(dialogData));
    const dialogResult = await dialogRef.afterClosed().toPromise();
    if (dialogResult.event === DialogActions.REJECT) { return null; }
    if (!dialogResult.data.createNewVersion) {
      const confirmationData: SimpleDialogData = this.componentHandler.getOverwriteConfirmationDialogData();
      const confirmationDialogRef = this.dialog.open(SimpleConfirmDialogComponent, getDialogConfig(confirmationData));
      const confirmationResult = await confirmationDialogRef.afterClosed().toPromise();
      if (confirmationResult.event === DialogActions.REJECT) { return; }
    }
    return dialogResult.data.createNewVersion;
  }

  private putComponent(packagingComponent: PackagingComponentDto, shouldCreateNewVersion: boolean) {
    this.spinner.show();
    const componentTypeName = this.getComponentTypeNameEnglish();
    this.putComponentSubscription = this.componentApiService.putPackagingComponent(
      packagingComponent, componentTypeName, shouldCreateNewVersion, true)
      .subscribe(response => {
        console.log('Response is: ', response);
        this.getComponentSubscription = this.componentApiService.getPackagingComponent(componentTypeName, response.id)
          .subscribe(savedComponent => {

            Object.keys(this.form.controls).forEach(control => {
              this.form.controls[control].markAsPristine();
            });
            this.filesEdited = false;

            const dialogData = this.componentHandler.getComponentSucessDialogData();
            const alertDialogConfig = getDialogConfig(dialogData, '400px');
            const dialogResult = this.dialog.open(SimpleAlertDialogComponent, alertDialogConfig);
            this.dialogSubscription = dialogResult.afterClosed().subscribe(_ => {
              if (this.componentTypeService.getDecorationSubtypeIds()
                .includes(packagingComponent.packagingComponentSubtypeId)) {
                this.navigationService.navigateToHistoryDecoration(
                  componentTypeName, savedComponent.id ?? -1, savedComponent.version ?? -1);
              } else {
                this.navigationService.navigateToHistoryComponent(
                  componentTypeName, savedComponent.id ?? -1, savedComponent.version ?? -1);
              }
            });
          });
      },
        (error) => {
          console.error('An error occurred, ', error);
          const dialogData = this.componentHandler.getFailureDialogData(error);
          const alertDialogConfig = getDialogConfig(dialogData, '400px');
          this.dialog.open(SimpleAlertDialogComponent, alertDialogConfig);
          this.spinner.hide();
        });
  }

  onFilesEdited() {
    this.filesEdited = true;
  }

  setUpLockAndHeartbeat(packagingComponent?: PackagingComponentDto) {
    this.heartBeatSubscription?.unsubscribe();
    if (this.isUserValidator || this.isPreview) {
      return;
    }
    const componentId = packagingComponent?.id;
    if (componentId == null) {
      this.heartBeatSubscription = interval(TEN_MINUTES)
        .pipe(
          startWith(this.authService.heartbeat()),
          mergeMap(() => this.authService.heartbeat())
        )
        .subscribe((_: any) => {
          console.log('heartbeat');
        });
    } else {
      const lockFunction = () => this.componentApiService.lockPackagingComponent(componentId, true);
      this.heartBeatSubscription = interval(FOUR_MINUTES)
        .pipe(
          startWith(lockFunction()),
          mergeMap(() => lockFunction())
        )
        .subscribe(
          (_: any) => {
            console.log('lock');
          },
          error => {
            if (error.status === 409) {
              this.componentHandler.showLockedDialog(this.dialog);
              this.form.disable();
              this.isFormLocked = true;
            }
          }
        );
    }
  }

  protected setChangedMultiMaterials(allMaterials: MultiMaterialCompositeDto[], changedMaterialIds: number[]) {
    changedMaterialIds.forEach(id => {
      const changedMaterial = allMaterials.find(x => x.underlyingMultiMaterialId === id);
      if (changedMaterial) {
        this.changedMaterialsInfo.push({
          id: changedMaterial.underlyingMultiMaterialId ?? -1,
          name: changedMaterial.articleName,
          packagingPart: PackagingPart.Material,
          type: '',
        });
      }
    });
  }

  protected async handleMaterialChangeParent(componentId: number, materialId: number, accepted: boolean) {
    const shouldCreateNewVersion = await this.shouldCreateNewVersion();
    if (shouldCreateNewVersion == null) { return; }
    if (accepted) {
      this.materialChangeSubscription = this.componentApiService.acceptMaterialChange(
        componentId, materialId, shouldCreateNewVersion).subscribe(_ => window.location.reload());
    } else {
      this.materialChangeSubscription = this.componentApiService.declineMaterialChange(
        componentId, materialId).subscribe(_ => window.location.reload());
    }
  }

  ngOnDestroy(): void {
    this.putComponentSubscription?.unsubscribe();
    this.getComponentSubscription?.unsubscribe();
    this.releaseSubscription?.unsubscribe();
    this.lockSubscription?.unsubscribe();
    this.routeParamsSubscription?.unsubscribe();
    this.routeDataSubscription?.unsubscribe();
    this.saveFilesSubscription?.unsubscribe();
    this.dialogSubscription?.unsubscribe();
    this.gtinChangesSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
    this.valueChangeSubscription?.unsubscribe();
    this.historySubscription?.unsubscribe();
    this.renamingSubscription?.unsubscribe();
    this.heartBeatSubscription?.unsubscribe();
    this.materialChangeSubscription?.unsubscribe();
  }
}
