import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ParentApiService } from './parent-api-service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContractDocumentDto} from 'src/app/data-transfer/entities/contract-document-dto';

@Injectable({ providedIn: 'root' })
export class ContractApiService extends ParentApiService {

    constructor(private http: HttpClient) {
        super();
    }

    public getunsignedContracts() {
        const url = `${environment.webApiAddress}/api/documents/unsigned-documents`;
        return this.http.get<ContractDocumentDto[]>(url);
    }
    public signContracts(ids: number[] ) {
        const url = `${environment.webApiAddress}/api/documents/sign-documents`;
        return this.http.put<Boolean>(url,ids); 
    }
    
}


