import { AnalysisParentComponent } from './../analysis-parent/analysis-parent.component';
import { ColorThemeService } from './../../../navigation/services/color-theme-service';
import { ValidatorApiService } from '../../../data-transfer/services/validator-api-service';
import { AuthService } from '../../../services/auth-service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CreditsService } from 'src/app/services/credits-service';
import { NormalizationComponent, NormalizationData } from '../../shared-components/normalization/normalization.component';
import { ECOINVENT_VERSION } from 'src/app/model/life-cycle-data-information';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { PackagingUnitText } from 'src/app/model/path-building-blocks';
import { PackagingUnitLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-unit-life-cycle-result-dto';
import { PackagingSystemLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-life-cycle-result-dto';
import { PackagingSystemDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-dto';
import { CountriesService } from 'src/app/navigation/services/countries-service';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { LicenseService } from 'src/app/services/licensing-service'
import { CountryDto } from 'src/app/data-transfer/entities/country-dto';
import { BuyType } from 'src/app/components/shared-components/shop/buying-from-shop/buying-from-shop.component'
import { EnvironmentalEffectDto } from 'src/app/data-transfer/entities/environmental-effect-dto';

export class SimplifiedLcaDataSource {
  validationState!: number;
  countryName!: string;
  evaluatedCountryCode!: string;
  analysisId!: string;
}

@Component({
  selector: 'app-analysis-life-cycle',
  templateUrl: './analysis-life-cycle.component.html',
  styleUrls: ['./analysis-life-cycle.component.scss']
})
export class AnalysisLifeCycleComponent extends AnalysisParentComponent implements OnInit {

  @ViewChild('normForm') normForm!: NormalizationComponent;

  simplifiedLcaDataSource: SimplifiedLcaDataSource[] = [];

  actualVolume: number | null = null;
  actualWeight: number | null = null;
  analysisVersions: (PackagingUnitLifeCycleResultDto[] | PackagingSystemLifeCycleResultDto[])[] = [];
  selectedAnalysisVersion: PackagingUnitLifeCycleResultDto[] | PackagingSystemLifeCycleResultDto[] = [];
  ecoinventVersion = ECOINVENT_VERSION;
  packagingSystem: PackagingSystemDto | null = null;
  packagingUnit: PackagingUnitDto | null = null;

  allowedCountries: CountryDto[] = [];
  authorizedCountries : Map<string, CountryDto[]> = new Map();

  allowedEffects : EnvironmentalEffectDto[] = [];
  authorizedEffects : Map<string, EnvironmentalEffectDto[]> = new Map();

  normalizationData: NormalizationData | null = null;
  displayedTotalValue!: number;

  buyType = BuyType;

  constructor(
    protected validatorApiService: ValidatorApiService,
    protected colorThemeService: ColorThemeService,
    protected authService: AuthService,
    protected creditsService: CreditsService,
    private route: ActivatedRoute,
    protected countriesService: CountriesService,
    protected licenseService : LicenseService,
  ) {
    super(validatorApiService, colorThemeService, authService, creditsService);
    this.allowedCountries = licenseService.allowedCountries;
    this.allowedEffects = licenseService.allowedEffects;
  }

  ngOnInit(): void {
    this.routeParamsSubscription = this.route.params.subscribe(params => {
      this.analysisId = params.analysisVersionId ?? '';
    });
    this.routeUrlSubscription = this.route.url.subscribe(segments => {
      this.callerLevelId = segments.map(x => x.path).includes(PackagingUnitText) ? PackagingPart.Unit : PackagingPart.System;
    })
    this.routeDataSubscription = this.route.data.subscribe(data => {
      const packagingPart = data.packagingUnit ? data.packagingUnit : data.packagingSystem;
      if (this.callerLevelId === PackagingPart.System) {
        this.packagingSystem = packagingPart;
      } else if (this.callerLevelId === PackagingPart.Unit) {
        this.packagingUnit = packagingPart;
        this.actualVolume = packagingPart.packagingVolume;
        this.actualWeight = packagingPart.packagingQuantity;
        this.packagingUnitTypeId = packagingPart.packagingTypeId;
      }
      super.setPackagingPartData(packagingPart);
      this.analysisVersions = data.lifeCycleEvaluationHistoryResults;
      this.selectedAnalysisVersion = this.getSelectedAnalysisVersion();
      this.setAuthorizations(this.selectedAnalysisVersion[0].evaluationReferenceTimestamp);
      this.setSimplifiedDataSourceForVersion(this.selectedAnalysisVersion);
    });
  }

  doNormalize(normData: NormalizationData) {
    if (normData.normalizeByQuantity || normData.normalizeByVolume || normData.normalizeByWeight) {
      this.normalizationData = normData;
    } else {
      this.normalizationData = null;
    }
  }

  normalizeProvidedLcaData(lcaDataToNormalize: any) {
    if (lcaDataToNormalize != null && this.normalizationData != null) {
      this.normForm.normalizeLcaResultData(lcaDataToNormalize, this.normalizationData);
    }
  }

  resetNormalizationData(data: { volume: number, weight: number }) {
    this.normForm?.resetData();
    this.actualVolume = data.volume;
    this.actualWeight = data.weight;
  }

  validateAnalysis(valid: boolean, evaluatedCountry: SimplifiedLcaDataSource) {
    super.validateAnalysis(valid, evaluatedCountry);
  }

  onHistoryVersionChange(version: PackagingUnitLifeCycleResultDto[] | PackagingSystemLifeCycleResultDto[]) {
    this.analysisId = version[0].id ?? '';
    this.setAuthorizations(version[0].evaluationReferenceTimestamp)
    this.setSimplifiedDataSourceForVersion(version);
  }

  getSelectedAnalysisVersionSystem(countryIndex: number): PackagingSystemLifeCycleResultDto {
    return this.selectedAnalysisVersion[countryIndex] as PackagingSystemLifeCycleResultDto;
  }

  getSelectedAnalysisVersionUnit(countryIndex: number): PackagingUnitLifeCycleResultDto {
    return this.selectedAnalysisVersion[countryIndex] as PackagingUnitLifeCycleResultDto;
  }

  private setSimplifiedDataSourceForVersion(dto: PackagingUnitLifeCycleResultDto[] | PackagingSystemLifeCycleResultDto[]) {
    this.simplifiedLcaDataSource = [];
    dto.forEach(lcaResult => {
      this.simplifiedLcaDataSource.push({
        validationState: lcaResult.validationState,
        countryName: this.countriesService.getCountryNameByCode(lcaResult.evaluatedCountry),
        evaluatedCountryCode: lcaResult.evaluatedCountry,
        analysisId: lcaResult.id
      });
    });
  }

  private getSelectedAnalysisVersion() {
    return this.callerLevelId === PackagingPart.Unit ?
      (this.analysisVersions as PackagingUnitLifeCycleResultDto[][]).find(x => x[0].id === this.analysisId) ?? this.analysisVersions[0] :
      (this.analysisVersions as PackagingSystemLifeCycleResultDto[][]).find(x => x[0].id === this.analysisId) ?? this.analysisVersions[0];
  }

  public isCountryAuthorized (country : SimplifiedLcaDataSource) : boolean {
    return this.isCountryInAuthorizedList(country.evaluatedCountryCode, this.allowedCountries);
  }

  private async isCountryAuthorizedOnTimestamp (countryCode : string, timestamp : string) : Promise<boolean> {
    const allowedCountriesOnTime = await this.getCountryAuthorizations(timestamp);
    return this.isCountryInAuthorizedList(countryCode, allowedCountriesOnTime);
  }

  private isCountryInAuthorizedList(countryCode: string, authorizations : CountryDto[]) {
    return authorizations.find((x) => x.code === countryCode) != undefined;
  }
  private async setAuthorizations (time: string) {
    this.allowedCountries = await this.getCountryAuthorizations(time);
    this.allowedEffects = await this.getEffectAuthorizations(time);
  }
  private async getCountryAuthorizations (time: string) {
    let tempCountries : CountryDto[] | undefined = this.authorizedCountries.get(time);
    if (tempCountries == undefined) {
      tempCountries = await this.licenseService.getAuthorizedCountriesFromTimeStampAsync(time);
      this.authorizedCountries.set(time,tempCountries);
    }
    return tempCountries;
  }

  private async getEffectAuthorizations (time: string) {
    let tempEffects : EnvironmentalEffectDto[] | undefined = this.authorizedEffects.get(time);
    if (tempEffects == undefined) {
      tempEffects = await this.licenseService.getAuthorizedEnvironmentalEffectsFromTimeStampAsync(time);
      this.authorizedEffects.set(time,tempEffects);
    }
    return tempEffects;
  }
}
